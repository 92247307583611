import React from 'react'
import { TextInput, View, ViewProps, Text } from 'react-native'
import { CardComponentStringProperty } from '../../../../classes'
import { useStyles } from '../../../../hooks'
import { Component } from '../../../../types'
import FormLabel from '../../../FormLabel'
import Select from '../../../Select'
import { CardEditorField } from '../../CardEditor.store'
import * as _styles from './CardEditorStringProperty.styles'

export type CardEditorStringPropertyProps = ViewProps & {
  property: CardComponentStringProperty
  onChange: (value: CardComponentStringProperty['value']) => void
  fields: CardEditorField[]
}

const CardEditorStringProperty: Component<CardEditorStringPropertyProps> = ({
  property,
  onChange,
  fields,
  ...others
}) => {
  const styles = useStyles(_styles)

  return (
    <View {...others} style={[styles.container, others.style]}>
      <FormLabel label="Value Type" style={{ width: 100 }}>
        <Select
          style={styles.select}
          options={
            [
              { label: 'Text', value: 'text' },
              { label: 'Field', value: 'field' }
            ] as const
          }
          value={property.value.type}
          onValueChange={(value) => {
            onChange({
              type: value,
              value: value === 'text' ? 'Text' : ''
            })
          }}
        />
      </FormLabel>
      {property.value.type === 'text' && (
        <FormLabel label="Text Value" style={styles.child}>
          <TextInput
            placeholder="Enter a value"
            style={styles.input}
            onChangeText={(newText) => onChange({ type: 'text', value: newText })}
            defaultValue={property.value.value}
          />
        </FormLabel>
      )}
      {property.value.type === 'field' && (
        <FormLabel label="Field Value" style={styles.child}>
          {fields.length > 0 ? (
            <Select
              style={styles.select}
              options={fields.map((field) => ({
                label: field.name,
                value: field.name
              }))}
              value={property.value.value}
              onValueChange={(value) => onChange({ type: 'field', value })}
            />
          ) : (
            <Text style={styles.emptyText}>No fields created yet</Text>
          )}
        </FormLabel>
      )}
    </View>
  )
}

export default CardEditorStringProperty
