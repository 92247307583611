import React from 'react'
import { Text } from 'react-native'
import ImageIcon from '../../../svg/Image'
import { CardComponent, CardComponentProperty, CardComponentStatic } from '../CardComponent'

export type ImagePropertyMap = {}

class _ImageCardComponent implements CardComponent {
  public static label = 'Image'
  public static icon = ImageIcon
  public static group = 'Media'
  public static key = 'Image'

  private onChangeFunc: (<Prop extends keyof ImagePropertyMap>(
    key: Prop,
    value: ImagePropertyMap[Prop]
  ) => void)[] = []

  getProperties(): CardComponentProperty[] {
    return []
  }

  setProperty<Prop extends keyof ImagePropertyMap>(
    key: Prop,
    value: ImagePropertyMap[Prop]
  ): CardComponent {
    this[key] = value as any
    this.onChangeFunc?.forEach((func) => func(key, value))
    return this
  }

  onPropChange(
    func: <Prop extends keyof ImagePropertyMap>(key: Prop, value: ImagePropertyMap[Prop]) => void
  ): void {
    this.onChangeFunc.push(func)
  }

  removeOnPropChange(
    func: <Prop extends keyof ImagePropertyMap>(key: Prop, value: ImagePropertyMap[Prop]) => void
  ) {
    this.onChangeFunc = this.onChangeFunc.filter((currFunc) => currFunc !== func)
  }

  getRendered(): (props: Record<string, unknown>) => JSX.Element {
    return (props) => (
      <React.Fragment {...props}>
        <Text>Image</Text>
      </React.Fragment>
    )
  }
  toObject(): { key: string; props: Record<string, unknown> } {
    return {
      key: ImageCardComponent.key,
      props: {}
    }
  }

  public static fromObject(obj: { key: string; props: Record<string, unknown> }) {
    if (obj.key !== ImageCardComponent.key) throw "Keys don't match"
    return new _ImageCardComponent()
  }
}

export const ImageCardComponent = _ImageCardComponent satisfies CardComponentStatic
export default ImageCardComponent
