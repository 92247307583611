import { BottomTabBarProps } from '@react-navigation/bottom-tabs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, TouchableOpacity, View } from 'react-native'

import { useToast } from 'react-native-toast-notifications'
import { Deck } from '../../../packages/misu/deck/models'
import { DEFAULT_NEW_CARDS_PER_DECK_PER_DAY } from '../../../packages/misu/deck-config/constants'
import { DeckConfig } from '../../../packages/misu/deck-config/models'
import { nanoid } from '../../../packages/misu/utils'
import { useMisuStorage, useStyles } from '../../hooks'
import { useDeckListScreenStore } from '../../screens/DeckListScreen/DeckListScreen.store'
import AddIcon from '../../svg/AddCircle'
import AddFilledIcon from '../../svg/AddCircleFilled'
import HomeIcon from '../../svg/Home'
import HomeFilledIcon from '../../svg/HomeFilled'
import SettingsIcon from '../../svg/Settings'
import SettingsFilledIcon from '../../svg/SettingsFilled'

import { Component } from '../../types'
import Button from '../Button'
import Input from '../Input'
import Modal from '../Modal'
import * as _styles from './Navigation.styles'

export type NavigationProps = BottomTabBarProps

export const Navigation: Component<NavigationProps> = ({ navigation, state }) => {
  const styles = useStyles(_styles)
  const [t] = useTranslation('common')
  const { storage } = useMisuStorage()
  const toast = useToast()
  const deckScreenState = useDeckListScreenStore()

  const activeRoute = state.routeNames[state.index]

  const [createLoading, setCreateLoading] = useState(false)
  const createDeck = async (name: string) => {
    try {
      if (createLoading) return
      if (!name) throw 'error.empty-name'
      setCreateLoading(true)
      const configId = nanoid()
      const deckId = nanoid()
      const now = Date.now()
      await storage?.deck.create(
        new Deck({
          config_id: configId,
          created_at: now,
          description: '',
          id: deckId,
          name,
          updated_at: now
        }),
        new DeckConfig({
          id: configId,
          active: 1,
          created_at: now,
          max_new_cards_per_day: null,
          max_review_cards_per_day: DEFAULT_NEW_CARDS_PER_DECK_PER_DAY,
          updated_at: Date.now()
        })
      )
      toast.show(t('success.created-deck'), { type: 'success' })
      deckScreenState.onChange()
      setCreateModalOpen(false)
    } catch (err) {
      console.error(err)
      const langCode = typeof err === 'string' ? err : 'error.creating-deck'
      toast.show(t(langCode as any), { type: 'danger' })
    }
    setCreateLoading(false)
  }

  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [deckName, setDeckName] = useState('')

  const links = [
    { path: 'home', icon: HomeIcon, filledIcon: HomeFilledIcon },
    {
      path: 'create',
      onClick: () => setCreateModalOpen(true),
      icon: AddIcon,
      filledIcon: AddFilledIcon
    },
    { path: 'settings', icon: SettingsIcon, filledIcon: SettingsFilledIcon }
  ] as const

  return (
    <View style={[styles.container]}>
      {links.map((link) => (
        <NavigationButton
          item={link}
          key={link.path}
          active={
            (activeRoute === link.path && !createModalOpen) ||
            (createModalOpen && link.path === 'create')
          }
          onClick={() => {
            if ('onClick' in link) {
              link.onClick?.()
              return
            }
            navigation.navigate(link.path)
          }}
        />
      ))}
      <Modal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        title="Create a new deck"
      >
        <Input
          size="compact"
          placeholder={t('input.name')}
          value={deckName}
          onChangeText={(text) => setDeckName(text)}
        />
        <Button size="compact" onPress={() => createDeck(deckName)} loading={createLoading}>
          {t('button.confirm')}
        </Button>
      </Modal>
    </View>
  )
}
export default Navigation

export type NavigationButtonProps = {
  item: {
    path: string
    onClick?: () => void
    icon: typeof HomeIcon
    filledIcon: typeof HomeFilledIcon
  }
  active: boolean
  onClick: () => void
}

export const NavigationButton: Component<NavigationButtonProps> = ({ active, item, onClick }) => {
  const styles = useStyles(_styles)
  const [t] = useTranslation('common')

  const Icon = active ? item.filledIcon : item.icon

  return (
    <TouchableOpacity
      activeOpacity={1}
      style={[styles.button, active && styles.buttonActive]}
      onPress={onClick}
    >
      <Icon style={[styles.buttonIcon, active && styles.buttonIconActive]} />
      <Text style={[styles.buttonText, active && styles.buttonTextActive]}>
        {t(('button.' + item.path) as any)}
      </Text>
    </TouchableOpacity>
  )
}
