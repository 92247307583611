import React, { useCallback, useState } from 'react'
import { View, ViewProps, ScrollView, LayoutChangeEvent } from 'react-native'
import { useStyles } from '../../../hooks'
import { useCardEditorState } from '../CardEditor.store'
import * as _styles from './CardPreview.styles'

export type CardPreviewProps = ViewProps

const CardPreview = React.forwardRef<View, CardPreviewProps>(({ ...others }, ref) => {
  const styles = useStyles(_styles)
  const state = useCardEditorState()
  const [containerBounds, setContainerBounds] = useState({
    width: 0,
    height: 0,
    x: 0,
    y: 0
  })

  const select = useCallback(
    (i: number) => {
      state.setSelected(state.cardState[i])
    },
    [state.cardState]
  )

  const deselect = useCallback(() => {
    state.setSelected(null)
  }, [])

  return (
    <View
      {...others}
      style={[styles.card, others.style]}
      ref={ref}
      onLayout={(e: LayoutChangeEvent) => {
        let target = e.target ?? (e.nativeEvent as any).target
        target.measure((x, y, w, h) => setContainerBounds({ width: w, height: h, x, y }))
        others.onLayout?.(e)
      }}
    >
      <ScrollView overScrollMode="never" bounces={false}>
        <View style={styles.cardInner}>
          {state.cardState.map((cardComponent, i) => {
            const Comp = cardComponent.getRendered()
            const isSelected = state.selected === cardComponent
            return (
              <Comp
                key={i}
                onSelect={() => select(i)}
                onDeselect={() => state.selected === cardComponent && deselect()}
                selected={isSelected}
                preview={state.preview}
                containerBounds={containerBounds}
                fields={state.fields}
              />
            )
          })}
        </View>
      </ScrollView>
    </View>
  )
})

export default CardPreview
