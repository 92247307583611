import { Kysely } from 'kysely'
import { AnkiNoteType } from '../../../anki/note-type/types'
import { DatabaseSchema15 as AnkiDatabase } from '../../../anki/types'
import { NoteTypeFactory as MisuNoteTypeFactory } from '../../note-type/factories'
import { NoteType } from '../../note-type/models'
import { MisuStorage } from '../../storage/models'
import { arrayBufferToUtf8 } from '../../utils'

export class NoteTypeConverter {
  private misuDb: MisuStorage
  private ankiDb: Kysely<AnkiDatabase>
  private idMap: Record<number, string> = {}

  constructor(misuDb: MisuStorage, ankiDb: Kysely<AnkiDatabase>) {
    this.misuDb = misuDb
    this.ankiDb = ankiDb
    this.idMap = {}
  }

  async insert(misuNoteTypesBatch: NoteType[]) {
    await this.misuDb.noteType.createMultiple(misuNoteTypesBatch)
  }

  private async processBatch(batch: AnkiNoteType[]): Promise<void> {
    const misuNoteTypesBatch: NoteType[] = []
    for (const noteType of batch) {
      const config = JSON.parse(arrayBufferToUtf8(noteType.config))
      const misuNoteType = MisuNoteTypeFactory.create({
        name: noteType.name,
        css: config.css,
        type: config.type,
        question_format: config.tmpls[0].qfmt,
        answer_format: config.tmpls[0].afmt
      })
      misuNoteTypesBatch.push(misuNoteType)
      this.idMap[noteType.id] = misuNoteType.id
    }
    await this.insert(misuNoteTypesBatch)
  }

  async convert(): Promise<Record<number, string>> {
    const ankiNoteTypes = await this.ankiDb.selectFrom('notetypes').selectAll().execute()
    const batchSize = 500
    for (let i = 0; i < ankiNoteTypes.length; i += batchSize) {
      const batch = ankiNoteTypes.slice(i, i + batchSize)
      await this.processBatch(batch)
    }
    return this.idMap
  }
}
