import { ViewStyle } from 'react-native'
import { Theme } from '../../../themes'

export const card = ({ background, radius }: Theme): ViewStyle => ({
  height: 200,
  width: 200,
  backgroundColor: background.card,
  borderRadius: radius.card,
  padding: 12,
  gap: 8
})

export const cardInner = ({}: Theme): ViewStyle => ({
  gap: 8
})
