import { Platform, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const input = ({ radius, colors }: Theme): ViewStyle => ({
  borderRadius: radius.input,
  backgroundColor: colors.input.background,
  alignItems: 'center',
  flexDirection: 'row'
})

export const input_size_default = ({ radius }: Theme): ViewStyle => ({
  borderRadius: radius.input,
  padding: Platform.OS === 'web' ? 0 : 16,
  height: 60
})

export const input_size_compact = ({ radius }: Theme): ViewStyle => ({
  borderRadius: radius.input * 0.75,
  paddingHorizontal: Platform.OS === 'web' ? 0 : 8,
  height: 42
})
