import React, { useMemo } from 'react'
import { View, ViewProps, Text, ScrollView, TouchableOpacity } from 'react-native'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import { CardComponentClassType, cardComponents } from '../../../classes/CardRendering'
import { useStyles } from '../../../hooks'
import SettingsIcon from '../../../svg/Settings'
import { Component } from '../../../types'
import { groupBy } from '../../../util'
import IconButton from '../../IconButton'
import { useCardEditorState } from '../CardEditor.store'
import * as _styles from './CardEditorDragList.styles'

export type CardEditorDragListProps = ViewProps & {
  previewBounds: { x: number; y: number; width: number; height: number }
}

const CardEditorDragList: Component<CardEditorDragListProps> = ({ previewBounds, ...others }) => {
  const styles = useStyles(_styles)
  const state = useCardEditorState()

  const groupedComponents = useMemo(() => {
    return groupBy(cardComponents, 'group')
  }, [cardComponents])

  const onDragEnd = (pos: { x: number; y: number }) => {
    if (!state.currentDrag) return
    if (pos.x >= previewBounds.x && pos.x <= previewBounds.x + previewBounds.width) {
      if (pos.y >= previewBounds.y && pos.y <= previewBounds.y + previewBounds.height) {
        state.setCardState([...state.cardState, new state.currentDrag.cardComponent()])
      }
    }
  }

  return (
    <View {...others} style={[styles.container, others.style]}>
      <ScrollView contentContainerStyle={{ position: 'relative' }}>
        <View style={{ position: 'relative' }}>
          <IconButton
            icon={SettingsIcon}
            style={styles.settingsButton}
            onPress={() => state.setSettingsOpen(true)}
          />
          {Object.entries(groupedComponents).map(([heading, cardComponents]) => (
            <View style={styles.groupContainer} key={heading}>
              <Text style={styles.groupHeading}>{heading}</Text>
              <ScrollView horizontal>
                <View style={styles.groupChildren}>
                  {cardComponents.map((cardComponent) => (
                    <CardDraggable
                      key={cardComponent.label}
                      cardComponent={cardComponent}
                      onDragEnd={onDragEnd}
                    />
                  ))}
                </View>
              </ScrollView>
            </View>
          ))}
        </View>
      </ScrollView>
    </View>
  )
}

export default CardEditorDragList

export type CardDraggableProps = ViewProps & {
  cardComponent: CardComponentClassType
  canDrag?: boolean
  onDragEnd?: (args: { x: number; y: number }) => void
}

export const CardDraggable = ({
  cardComponent,
  canDrag = true,
  onDragEnd,
  ...others
}: CardDraggableProps) => {
  const styles = useStyles(_styles)
  const Icon = cardComponent.icon
  const state = useCardEditorState()

  const dragGesture = Gesture.Pan()
    .activateAfterLongPress(300)
    .runOnJS(true)
    .onStart((e) => {
      if (!canDrag) return
      state.setCurrentDrag({
        cardComponent,
        x: e.absoluteX,
        y: e.absoluteY
      })
    })
    .onUpdate((e) => {
      state.setCurrentDrag({
        cardComponent,
        x: e.absoluteX,
        y: e.absoluteY
      })
    })
    .onTouchesUp((e) => {
      onDragEnd?.({ x: e.allTouches[0].absoluteX, y: e.allTouches[0].absoluteY })
      state.setCurrentDrag(null)
    })
    .onFinalize(() => {
      state.setCurrentDrag(null)
    })

  const nullGesture = Gesture.Tap()

  return (
    <GestureDetector gesture={canDrag ? dragGesture : nullGesture}>
      <TouchableOpacity {...others} activeOpacity={0.8} style={[styles.draggable, others.style]}>
        <Icon style={styles.draggableIcon} />
        <Text style={styles.draggableLabel}>{cardComponent.label}</Text>
      </TouchableOpacity>
    </GestureDetector>
  )
}
