import { TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const container = ({ colors, radius }: Theme): ViewStyle => ({
  height: 48,
  backgroundColor: colors.input.background,
  borderWidth: 1,
  borderColor: colors.input.border,
  borderRadius: radius.input,
  paddingHorizontal: 12,
  paddingVertical: 8,
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center'
})

export const icon = ({ fonts }: Theme): TextStyle => ({
  color: fonts.textMain.color,
  width: 24,
  height: 24
})

export const valueText = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textMain
})

export const placeholderText = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textSecondary
})

export const modalBackdrop = ({}: Theme): ViewStyle => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  opacity: 0.5,
  backgroundColor: '#000'
})

export const modal = ({ background, radius }: Theme): ViewStyle => ({
  padding: 16,
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  maxHeight: 300,
  maxWidth: 400,
  marginHorizontal: 'auto',
  borderTopRightRadius: radius.card,
  borderTopLeftRadius: radius.card,
  backgroundColor: background.card
})

export const optionsContainer = (): ViewStyle => ({
  gap: 4
})

export const option = (): ViewStyle => ({
  flexDirection: 'row',
  gap: 12,
  padding: 8,
  alignItems: 'center',
  paddingLeft: 32
})

export const optionSelected = (): ViewStyle => ({
  paddingLeft: 0
})

export const tickIcon = ({ fonts }: Theme): TextStyle => ({
  color: fonts.textMain.color,
  width: 20,
  height: 20
})

export const optionText = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textMain,
  fontSize: 18,
  fontWeight: 'bold'
})
