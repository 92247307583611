import { AnkiCard } from '../../anki/card/types'
import { BaseFactory } from '../models'
import { Review } from '../review/models'
import { Rating } from '../study-queue/types'
import { TimestampMilliseconds } from '../timestamp'
import { AnkiCardAdapter } from './adapters'
import { Card } from './models'
import { CardQueue, CreateCardType } from './types'

export class CardFactory extends BaseFactory {
  static clone(card: Card) {
    return new Card({ ...card.toDTO() })
  }

  static create(data: CreateCardType) {
    return new Card({ ...this.createBase(data) })
  }

  static async createFromAnkiCard(
    data: AnkiCard,
    newDeckId: string,
    newCardId: string,
    schedulerInitDateSeconds: number
  ): Promise<Card> {
    const adapter = new AnkiCardAdapter(data)
    const adaptedData = await adapter.adapt(newDeckId, newCardId, schedulerInitDateSeconds)
    return new Card({ ...this.createBase(adaptedData), created_at: adaptedData.created_at })
  }

  static createFromCardAndReview(card: Card, review: Review): Card {
    let lapses: number = card.lapses
    switch (review.type) {
      case CardQueue.New:
        lapses = 0
        break
      case CardQueue.Review:
        lapses -= Rating.Incorrect === review.rating ? 1 : 0
        break
    }
    const data = {
      ...card.toDTO(),
      reps: card.reps - 1,
      type: review.type,
      interval: review.interval,
      due: review.due,
      left: review.left,
      lapses: lapses,
      fsrs_stability: review.fsrsState.stability,
      fsrs_difficulty: review.fsrsState.difficulty,
      updated_at: TimestampMilliseconds.now()
    }
    return new Card(data)
  }
}
