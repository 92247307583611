// TODO: Add tests for preview cards and spaced_learning cards
import { Kysely } from 'kysely'
import { AnkiCard } from '../../../anki/card/types'
import { DatabaseSchema15 as AnkiDatabase } from '../../../anki/types'
import { CardFactory as MisuCardFactory } from '../../card/factories'
import { Card } from '../../card/models'
import { MisuStorage } from '../../storage/models'

export class CardConverter {
  private misuDb: MisuStorage
  private ankiDb: Kysely<AnkiDatabase>

  constructor(misuDb: MisuStorage, ankiDb: Kysely<AnkiDatabase>) {
    this.misuDb = misuDb
    this.ankiDb = ankiDb
  }

  async insert(misuCardsBatch: Card[]) {
    await this.misuDb.card.createMultiple(misuCardsBatch)
  }

  private async processBatch(
    batch: AnkiCard[],
    schedulerInitDateSeconds: number,
    deckIdMap: Record<number, string>,
    noteIdMap: Record<number, string>
  ): Promise<Record<number, string>> {
    const cardIdMap: Record<number, string> = {}
    const misuCardsBatch: Card[] = []
    for (const card of batch) {
      const deckId = deckIdMap[card.did]
      const noteId = noteIdMap[card.nid]
      const misuCard = await MisuCardFactory.createFromAnkiCard(
        card,
        deckId,
        noteId,
        schedulerInitDateSeconds
      )
      misuCardsBatch.push(misuCard)
      cardIdMap[card.id] = misuCard.id
    }
    await this.insert(misuCardsBatch)
    return cardIdMap
  }

  async convert(
    schedulerInitDateSeconds: number,
    deckIdMap: Record<number, string>,
    noteIdMap: Record<number, string>
  ): Promise<Record<number, string>> {
    const batchSize = 500
    const ankiCards = await this.ankiDb.selectFrom('cards').selectAll().execute()
    let cardIdMap: Record<number, string> = {}
    for (let i = 0; i < ankiCards.length; i += batchSize) {
      const batch = ankiCards.slice(i, i + batchSize)
      cardIdMap = {
        ...cardIdMap,
        ...(await this.processBatch(batch, schedulerInitDateSeconds, deckIdMap, noteIdMap))
      }
    }
    return cardIdMap
  }
}
