import { useEffect, useState } from 'react'
import { NoteType } from '../../../packages/misu/note-type/models'
import { useMisuStorage } from './useMisuStorage'

export type UseNoteTypeResponse = {
  fetching: boolean
  noteTypes: NoteType[] | null
  error: unknown
}

export const useNoteTypes = (): UseNoteTypeResponse => {
  const [noteTypes, setNoteTypes] = useState<NoteType[] | null>(null)
  const [fetching, setFetching] = useState(true)
  const [error, setError] = useState<unknown>(null)
  const { storage } = useMisuStorage()

  useEffect(() => {
    if (!storage) return
    setFetching(true)
    let unmounted = false
    storage.noteType
      .getAll()
      .then((noteTypes) => {
        if (unmounted) return
        setNoteTypes(noteTypes)
      })
      .catch((err) => {
        if (unmounted) return
        setError(err)
      })
      .finally(() => {
        if (unmounted) return
        setFetching(false)
      })

    return () => {
      unmounted = true
    }
  }, [storage])

  return {
    noteTypes,
    fetching,
    error
  }
}
