import React, { useEffect, useMemo, useState } from 'react'
import { ScrollView, Text, View, ViewProps } from 'react-native'
import {
  CardComponent,
  CardComponentProperty,
  getCardComponentClassFromInstance
} from '../../../classes'
import { useStyles } from '../../../hooks'
import CloseIcon from '../../../svg/Close'
import { Component } from '../../../types'
import Button from '../../Button'
import IconButton from '../../IconButton'
import { CardEditorField, useCardEditorState } from '../CardEditor.store'
import CardEditorBooleanProperty from './CardEditorBooleanProperty'
import CardEditorEnumProperty from './CardEditorEnumProperty'
import CardEditorNumberProperty from './CardEditorNumberProperty'
import * as _styles from './CardEditorPropertyList.styles'
import CardEditorStringProperty from './CardEditorStringProperty'

export type CardEditorPropertyListProps = ViewProps & {
  cardComponent: CardComponent
}

const CardEditorPropertyList: Component<CardEditorPropertyListProps> = ({
  cardComponent,
  ...others
}) => {
  const styles = useStyles(_styles)
  const [forcedRefresh, _setForceRefresh] = useState(false)

  const forceRefresh = () => _setForceRefresh((val) => !val)

  const state = useCardEditorState()
  const staticClass = getCardComponentClassFromInstance(cardComponent)
  const properties = useMemo(() => cardComponent.getProperties(), [cardComponent, forcedRefresh])

  useEffect(() => {
    cardComponent.onPropChange(forceRefresh)
    return () => cardComponent.removeOnPropChange(forceRefresh)
  }, [cardComponent])

  return (
    <View
      {...others}
      style={[styles.container, others.style]}
      // @ts-ignore
      onClick={(e) => e.stopPropagation()}
      onTouchEnd={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      <Text style={styles.title}>{staticClass.label} Properties</Text>
      <IconButton
        icon={CloseIcon}
        style={styles.closeButton}
        onPress={() => state.setSelected(null)}
      />
      <ScrollView>
        <View style={styles.inner}>
          {properties.map((prop) => (
            <CardEditorPropertyView
              property={prop}
              key={prop.key}
              onChange={(val) => cardComponent.setProperty(prop.key, val)}
              fields={state.fields}
            />
          ))}
          <Button
            color="error"
            size="compact"
            onPress={() =>
              state.setCardState(state.cardState.filter((comp) => comp !== cardComponent))
            }
          >
            Delete Component
          </Button>
        </View>
      </ScrollView>
    </View>
  )
}

export default CardEditorPropertyList

export type CardEditorPropertyViewProps = ViewProps & {
  property:
    | CardComponentProperty
    | { type: 'custom'; key: string; label: string; value?: string | number }
  onChange: (val: unknown) => void
  fields: CardEditorField[]
}

const typeCompMap = {
  boolean: [
    CardEditorBooleanProperty,
    { style: { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' } }
  ],
  string: [CardEditorStringProperty],
  enum: [CardEditorEnumProperty],
  number: [CardEditorNumberProperty],
  custom: [null]
} as const satisfies Readonly<
  Record<
    CardComponentProperty['type'] | 'custom',
    Readonly<[comp: Component<any> | null, props?: ViewProps]>
  >
>

export const CardEditorPropertyView: Component<CardEditorPropertyViewProps> = ({
  onChange,
  style,
  ...others
}) => {
  const Comp = typeCompMap[others.property.type][0]
  const overrideProps = typeCompMap[others.property.type][1]
  const prop = others.property
  const styles = useStyles(_styles)

  return (
    <View {...overrideProps} style={[styles.propertyContainer, overrideProps?.style, style]}>
      <View style={styles.textContainer}>
        <Text style={styles.propertyLabel}>{prop.label}:</Text>
        <Text style={styles.propertyValue}>
          {prop.type === 'enum'
            ? prop.values.find((val) => val.value === prop.value)?.label
            : prop.type === 'string'
            ? prop.value.value
            : prop.value}
        </Text>
      </View>
      {Comp && <Comp {...(others as any)} onChange={(value) => onChange?.(value)} />}
      {others.children}
    </View>
  )
}
