import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Text, TextStyle } from 'react-native'
import ResizableView, { ResizableViewProps } from '../../../components/ResizableView'
import { usePressAway, useStyles } from '../../../hooks'
import { useTheme } from '../../../state/theme'
import TextIcon from '../../../svg/Text'
import { Theme } from '../../../themes'
import {
  CardComponent,
  CardComponentProperty,
  CardComponentStatic,
  CardComponentStringProperty,
  DefaultCardComponentProps
} from '../CardComponent'

export type TextBoxPropertyMap = {
  fontSize: number
  color: 'primary' | 'main'
  fontWeight: 'normal' | 'bold'
  textAlign: 'left' | 'center' | 'right'
  verticalAlign: 'top' | 'center' | 'bottom'
  text: CardComponentStringProperty['value']
  size: { width: number; height: number } | 'auto'
}

class _TextBoxCardComponent implements CardComponent {
  public static label = 'Text Box'
  public static icon = TextIcon
  public static group = 'Text'
  public static key = 'text_box'

  public fontSize: TextBoxPropertyMap['fontSize'] = 18
  public color: TextBoxPropertyMap['color'] = 'main'
  public fontWeight: TextBoxPropertyMap['fontWeight'] = 'bold'
  public textAlign: TextBoxPropertyMap['textAlign'] = 'left'
  public verticalAlign: TextBoxPropertyMap['verticalAlign'] = 'top'
  public text: TextBoxPropertyMap['text'] = { type: 'text', value: 'Text' }
  public size: TextBoxPropertyMap['size'] = 'auto'

  private onChangeFunc: (<Prop extends keyof TextBoxPropertyMap>(
    key: Prop,
    value: TextBoxPropertyMap[Prop]
  ) => void)[] = []

  getProperties(): CardComponentProperty[] {
    return [
      { label: 'Text', key: 'text', type: 'string', value: this.text },
      {
        label: 'Font Size',
        key: 'fontSize',
        type: 'number',
        value: this.fontSize,
        min: 12,
        max: 28
      },
      {
        label: 'Color',
        key: 'color',
        type: 'enum',
        values: [
          { value: 'main', label: 'Main' },
          { value: 'primary', label: 'Primary' }
        ],
        value: this.color
      },
      {
        label: 'Font Weight',
        key: 'fontWeight',
        type: 'enum',
        values: [
          { value: 'normal', label: 'Normal' },
          { value: 'bold', label: 'Bold' }
        ],
        value: this.fontWeight
      },
      {
        label: 'Horizontal Align',
        key: 'textAlign',
        type: 'enum',
        values: [
          { value: 'left', label: 'Left' },
          { value: 'center', label: 'Center' },
          { value: 'right', label: 'Right' }
        ],
        value: this.textAlign
      },
      {
        label: 'Vertical Align',
        key: 'verticalAlign',
        type: 'enum',
        values: [
          { value: 'top', label: 'Top' },
          { value: 'center', label: 'Center' },
          { value: 'bottom', label: 'Bottom' }
        ],
        value: this.verticalAlign
      }
    ]
  }

  setProperty<Prop extends keyof TextBoxPropertyMap>(
    key: Prop,
    value: TextBoxPropertyMap[Prop]
  ): CardComponent {
    this[key] = value as any
    this.onChangeFunc.forEach((func) => func(key, value))
    return this
  }

  getRendered(): (props: Omit<TextBoxRenderProps, 'cardComponent'>) => JSX.Element {
    return (props) => {
      return React.createElement(TextBoxRenderComp, { ...props, cardComponent: this })
    }
  }

  onPropChange(
    func: <Prop extends keyof TextBoxPropertyMap>(
      key: Prop,
      value: TextBoxPropertyMap[Prop]
    ) => void
  ): void {
    this.onChangeFunc.push(func)
  }

  removeOnPropChange(
    func: <Prop extends keyof TextBoxPropertyMap>(
      key: Prop,
      value: TextBoxPropertyMap[Prop]
    ) => void
  ) {
    this.onChangeFunc = this.onChangeFunc.filter((currFunc) => currFunc !== func)
  }

  toObject() {
    return {
      key: _TextBoxCardComponent.key,
      props: {
        fontSize: this.fontSize,
        fontWeight: this.fontWeight,
        color: this.color,
        textAlign: this.textAlign,
        verticalAlign: this.verticalAlign,
        text: this.text
      }
    }
  }

  static fromObject(obj: { key: string; props: Record<string, unknown> }): _TextBoxCardComponent {
    if (obj.key !== TextBoxCardComponent.key) throw 'Keys are not matching'
    const props = obj.props as Record<string, any>
    if (typeof props !== 'object') throw 'Props are invalid'
    let comp = new TextBoxCardComponent()
    comp.fontSize = props.fontSize
    comp.fontWeight = props.fontWeight
    comp.color = props.color
    comp.textAlign = props.textAlign
    comp.verticalAlign = props.verticalAlign
    comp.text = props.text
    return comp
  }
}

export type TextBoxRenderProps = DefaultCardComponentProps & {
  cardComponent: _TextBoxCardComponent
}

const TextBoxRenderComp = React.memo(
  ({
    onDeselect,
    onSelect,
    selected,
    cardComponent,
    preview,
    containerBounds,
    fields,
    ...others
  }: TextBoxRenderProps): JSX.Element => {
    const styles = useStyles(_styles)
    const theme = useTheme()
    const [_, setForcedRefresh] = useState(false)
    const viewBounds = {
      width: containerBounds.width - 4,
      height: cardComponent.fontSize + 24
    }
    const [size, setSize] = useState<{ width: number; height: number } | 'auto'>(cardComponent.size)

    const forceRefresh = useCallback(() => setForcedRefresh((val) => !val), [])

    useEffect(() => {
      if (!cardComponent) return
      cardComponent.onPropChange(forceRefresh)
      return () => cardComponent.removeOnPropChange(forceRefresh)
    }, [cardComponent])

    const callback = useCallback(() => onDeselect?.(), [onDeselect])

    const getSizeFrac = () => {
      let width = viewBounds.width
      let height = viewBounds.height
      return { width: width / containerBounds.width, height: height / containerBounds.height }
    }

    usePressAway(callback)

    const onDrag: ResizableViewProps['onDrag'] = (diff) => {
      let fracSize = getSizeFrac()
      setSize((oldSize) => ({
        width:
          (oldSize === 'auto' ? fracSize.width : oldSize.width) + diff.x / containerBounds.width,
        height:
          (oldSize === 'auto' ? fracSize.height : oldSize.height) + diff.y / containerBounds.height
      }))
    }

    const text = useMemo(() => {
      if (!preview) {
        return cardComponent.text.type === 'field'
          ? `{{${cardComponent.text.value}}}`
          : cardComponent.text.value
      }
      if (cardComponent.text.type === 'text') return cardComponent.text.value
      return fields.find((field) => field.name === cardComponent.text.value)?.value ?? ''
    }, [cardComponent.text.type, cardComponent.text.value])

    return (
      <ResizableView
        {...others}
        onPress={onSelect}
        enabled={selected && !preview}
        styles={{
          innerContainer: [
            styles.textContainer,
            preview && styles.preview,
            selected && styles.selected,
            size !== 'auto' && {
              width: '100%',
              height: '100%'
            },
            {
              justifyContent:
                cardComponent.verticalAlign === 'bottom'
                  ? 'flex-end'
                  : cardComponent.verticalAlign === 'center'
                  ? 'center'
                  : 'flex-start'
            }
          ]
        }}
        style={[
          size !== 'auto' && {
            width: size.width * (containerBounds.height - 28),
            height: size.height * (containerBounds.height - 28)
          }
        ]}
        onDrag={onDrag}
        onDragEnd={() => cardComponent.setProperty('size', size)}
      >
        <Text
          style={[
            styles.text,
            {
              fontSize: cardComponent.fontSize,
              fontWeight: cardComponent.fontWeight,
              textAlign: cardComponent.textAlign,
              color:
                cardComponent.color === 'main'
                  ? theme.fonts.textMain.color
                  : theme.colors.primary.main
            }
          ]}
        >
          {text}
        </Text>
      </ResizableView>
    )
  },
  (prev, next) =>
    prev.cardComponent === next.cardComponent &&
    prev.containerBounds === next.containerBounds &&
    prev.preview === next.preview &&
    prev.selected === next.selected
)

export const TextBoxCardComponent = _TextBoxCardComponent satisfies CardComponentStatic
export default TextBoxCardComponent

const _styles = {
  textContainer: ({ background, colors }: Theme) => ({
    backgroundColor: background.cardLight,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: colors.component.background
  }),
  text: ({ fonts }: Theme): TextStyle => ({
    ...fonts.titleRegular,
    fontSize: 18
  }),
  selected: ({ colors }: Theme): TextStyle => ({
    borderColor: colors.primary.main
  }),
  preview: (): TextStyle => ({
    paddingHorizontal: 0,
    paddingVertical: 0,
    borderWidth: 0,
    backgroundColor: 'transparent'
  })
}
