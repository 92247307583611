import { useNavigation } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, ViewProps } from 'react-native'
import { useToast } from 'react-native-toast-notifications'
import { Button, FormLabel, Input, Screen } from '../../components'
import { useDeck, useStyles } from '../../hooks'
import { Component, LoggedOutNavigationProp, LoggedOutParamsList } from '../../types'
import { useDeckListScreenStore } from '../DeckListScreen/DeckListScreen.store'
import * as _styles from './EditDeckScreen.styles'

export type EditDeckScreenProps = ViewProps & StackScreenProps<LoggedOutParamsList, 'edit-deck'>

const EditDeckScreen: Component<EditDeckScreenProps> = ({ route, ...others }) => {
  const styles = useStyles(_styles)
  const navigation = useNavigation<LoggedOutNavigationProp>()
  const { deck, update } = useDeck(route.params.deckId)
  const [t] = useTranslation('edit-deck')
  const [name, setName] = useState(deck?.name ?? '')
  const [desc, setDesc] = useState(deck?.description ?? '')
  const toast = useToast()

  const deckScreenState = useDeckListScreenStore()

  useEffect(() => {
    if (!deck) return
    setName(deck.name)
  }, [deck?.name])

  useEffect(() => {
    if (!deck) return
    setDesc(deck.description)
  }, [deck?.description])

  const save = async () => {
    try {
      await update({
        name,
        description: desc
      })
      deckScreenState.onChange()
      toast.show(t('success.updated-deck', { ns: 'common' }), { type: 'success' })
      navigation.getState().routeNames.includes('user')
        ? navigation.popToTop()
        : navigation.replace('user')
    } catch (err) {
      toast.show(t('error.updating-deck', { ns: 'common' }))
    }
  }

  return (
    <Screen>
      <View {...others} style={[styles.container, others.style]}>
        <FormLabel label={t('input.name', { ns: 'common' })}>
          <Input
            value={name}
            placeholder={t('placeholder.name', { ns: 'common' })}
            onChangeText={(text) => setName(text)}
          />
        </FormLabel>
        <FormLabel label={t('input.description', { ns: 'common' })}>
          <Input
            value={desc}
            placeholder={t('placeholder.description', { ns: 'common' })}
            onChangeText={(text) => setDesc(text)}
          />
        </FormLabel>
        <Button
          variant="outlined"
          style={{ marginTop: 16 }}
          onPress={() => navigation.navigate('edit-deck-cards', { deckId: route.params.deckId })}
        >
          {t('button.edit-cards')}
        </Button>
        <Button style={{ marginTop: 'auto' }} onPress={save}>
          {t('button.save', { ns: 'common' })}
        </Button>
      </View>
    </Screen>
  )
}

export default EditDeckScreen
