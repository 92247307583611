import { Slider as OldSlider, SliderProps } from '@miblanchard/react-native-slider'
import React from 'react'
import { View, Text, ViewStyle, StyleProp } from 'react-native'
import { useStyles } from '../../hooks'
import { useTheme } from '../../state/theme'
import { Component } from '../../types'

import * as _styles from './Slider.styles'

const Slider: Component<SliderProps> = (props) => {
  const theme = useTheme()
  const styles = useStyles(_styles)

  return (
    <OldSlider
      minimumTrackStyle={styles.minimumTrack as ViewStyle}
      maximumTrackStyle={styles.maximumTrack as ViewStyle}
      minimumTrackTintColor={theme.colors.primary.main}
      maximumTrackTintColor={theme.colors.component.background}
      thumbTintColor={theme.colors.primary.main}
      thumbStyle={styles.thumb as ViewStyle}
      renderAboveThumbComponent={(index, value) => Label(value, styles)}
      {...props}
    />
  )
}

export default Slider

export const Label = (
  value: number,
  styles: Record<string, StyleProp<ViewStyle>>
): React.ReactNode => {
  return (
    <View style={styles.label}>
      <Text style={styles.labelText}>{Math.floor(value)}</Text>
    </View>
  )
}
