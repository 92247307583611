import { CardComponent } from './CardComponent'
import { getCardComponentClassFromKey } from './CardComponents'

export const deserializeCardType = (
  items: { key: string; props: Record<string, unknown> }[]
): CardComponent[] => {
  return items.map((args) => {
    const comp = getCardComponentClassFromKey(args.key)
    const instance = comp.fromObject(args)
    return instance
  })
}

export const serializeCardType = (comps: CardComponent[]) => {
  return comps.map((comp) => comp.toObject())
}
