import { Kysely, sql } from 'kysely'
import { CardQueue } from '../../card/types'
import { Deck } from '../../deck/models'
import { DeckType, DueCountsQuery } from '../../deck/types'
import { DeckConfig } from '../../deck-config/models'
import { booleanToNumber } from '../../utils'
import { DeckConfigStorage } from '../deck-config/models'
import { Database } from '../types'

export class DeckStorage {
  private db: Kysely<Database>
  public config: DeckConfigStorage

  constructor(db: Kysely<Database>) {
    this.db = db
    this.config = new DeckConfigStorage(db)
  }

  async dueCounts(dayCutoff: number, learnCutoff: number): Promise<DueCountsQuery[]> {
    const results = await this.db
      .selectFrom('card')
      .select([
        'card.deck_id as id',
        (eb) => eb.fn.sum<number>(sql`card.type = ${CardQueue.New}`).as('new'),
        (eb) =>
          eb.fn
            .sum<number>(sql`card.type = ${CardQueue.Review} AND card.due <= ${dayCutoff}`)
            .as('review'),
        (eb) =>
          eb.fn
            .sum<number>(sql`card.type = ${CardQueue.Learning} AND card.due <= ${learnCutoff}`)
            .as('learning'),
        (eb) => eb.fn.countAll<number>().as('total')
      ])
      .groupBy('card.deck_id')
      .execute()
    return results
  }

  async get(id: string): Promise<Deck> {
    const deck = await this.db
      .selectFrom('deck')
      .selectAll()
      .where('deck.id', '=', id)
      .executeTakeFirst()

    if (!deck) {
      throw new Error(`Could not find deck with id ${id}.`)
    }

    return new Deck(deck)
  }

  async update(id: string, obj: Partial<DeckType>): Promise<Deck> {
    const deck = await this.db
      .updateTable('deck')
      .where('id', '=', id)
      .set(obj)
      .returningAll()
      .executeTakeFirstOrThrow()

    return new Deck(deck)
  }

  async getMultiple(ids: string[]): Promise<Deck[]> {
    const decks = await this.db.selectFrom('deck').selectAll().where('deck.id', 'in', ids).execute()
    return decks.map((deck) => new Deck(deck))
  }

  async getActive(): Promise<Deck[]> {
    const decks = await this.db
      .selectFrom('deck')
      .selectAll('deck')
      .leftJoin('deck_config', 'deck_config.id', 'deck.config_id')
      .where('deck_config.active', '=', booleanToNumber(true))
      .execute()
    return decks.map((deck) => new Deck(deck))
  }

  async delete(id: string): Promise<void> {
    await this.db.deleteFrom('deck').where('id', '=', id).execute()
  }

  async getAll(): Promise<Deck[]> {
    const decks = await this.db.selectFrom('deck').selectAll().orderBy('created_at desc').execute()
    return decks.map((deck) => new Deck(deck))
  }

  async create(deck: Deck, config: DeckConfig): Promise<void> {
    await this.db.transaction().execute(async (trx) => {
      const results = await trx.insertInto('deck').values(deck.toDTO()).executeTakeFirst()
      if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
        throw new Error('Could not insert deck.')
      }
      await this.config.create(config, trx)
    })
  }

  async getActiveDeckIds(): Promise<string[]> {
    const decks = await this.db
      .selectFrom('deck')
      .select('deck.id')
      .leftJoin('deck_config', 'deck_config.id', 'deck.config_id')
      .where('deck_config.active', '=', booleanToNumber(true))
      .execute()
    return decks.map((deck) => deck.id)
  }

  async getAllDeckIds(): Promise<string[]> {
    const decks = await this.db.selectFrom('deck').select('deck.id').execute()
    return decks.map((deck) => deck.id)
  }

  async createMultiple(decks: Deck[], configs: DeckConfig[]): Promise<void> {
    await this.db.transaction().execute(async (trx) => {
      const results = await trx
        .insertInto('deck')
        .values(decks.map((d) => d.toDTO()))
        .executeTakeFirst()
      if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
        throw new Error('Could not insert decks.')
      }
      await this.config.createMultiple(configs, trx)
    })
  }
}
