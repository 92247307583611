import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { useToast } from 'react-native-toast-notifications'
import { getDbHandler } from '../../../packages/interfaces/sqlite/generic'
import { Button } from '../../components'
import Screen from '../../components/Screen'
import { DB_NAME } from '../../constants'
import { useTheme } from '../../state/theme'
import { useUserStore } from '../../state/user'
import { LoggedInNavigationProp } from '../../types'

export const Settings = () => {
  const theme = useTheme()
  const navigation = useNavigation<LoggedInNavigationProp>()
  const userStore = useUserStore()
  const toast = useToast()
  const [t] = useTranslation(['settings', 'common'])

  const deleteDatabase = async () => {
    try {
      const dbHandler = await getDbHandler()
      await dbHandler.delete(DB_NAME)
      userStore.logout()
      toast.show(t('success.deleted-database'), { type: 'success' })
    } catch (err) {
      console.error(err)
      toast.show(t('error.deleting-database'), { type: 'error' })
    }
  }

  return (
    <Screen>
      <View style={{ padding: 16, gap: 16 }}>
        <Text style={{ ...theme.fonts.titleRegular, fontSize: 20 }}>{t('title')}</Text>
        <Button onPress={() => navigation.navigate('import-deck')}>
          {t('button.import-decks')}
        </Button>
        <Button onPress={() => userStore.logout()}>{t('common:button.logout')}</Button>
        <Button onPress={() => navigation.navigate('create-card-type')}>
          {t('button.create-card-type')}
        </Button>
        <Button onPress={deleteDatabase} color="error">
          {t('button.delete-database')}
        </Button>
      </View>
    </Screen>
  )
}

export default Settings
