import { TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../../themes'

export const container = ({ background }: Theme): ViewStyle => ({
  backgroundColor: background.card,
  height: 300
})

export const groupContainer = ({}: Theme): ViewStyle => ({})

export const groupHeading = ({ fonts, background }: Theme): TextStyle => ({
  backgroundColor: background.cardLight,
  ...fonts.titleRegular,
  fontSize: 20,
  padding: 8
})

export const settingsButton = ({}: Theme): ViewStyle => ({
  position: 'absolute',
  top: -3,
  right: 0,
  zIndex: 1
})

export const groupChildren = ({}: Theme): ViewStyle => ({
  paddingHorizontal: 8,
  paddingVertical: 12,
  flexDirection: 'row',
  gap: 8
})

export const draggable = ({ background }: Theme): ViewStyle => ({
  padding: 8,
  width: 80,
  height: 70,
  alignItems: 'center',
  justifyContent: 'center',
  gap: 4,
  backgroundColor: background.cardLight,
  borderRadius: 8
})

export const draggableIcon = ({ fonts }: Theme): TextStyle => ({
  width: 32,
  height: 32,
  color: fonts.textMain.color
})

export const draggableLabel = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textMain,
  fontSize: 14
})
