import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useRef, useState } from 'react'
import { Alert, Platform, View, ViewProps } from 'react-native'
import * as Animatable from 'react-native-animatable'
import { useToast } from 'react-native-toast-notifications'
import { Field } from '../../../packages/misu/field/models'
import { NoteType } from '../../../packages/misu/note-type/models'
import { nanoid } from '../../../packages/misu/utils'
import { cardComponents, serializeCardType } from '../../classes'
import { useMisuStorage, useStyles } from '../../hooks'
import { useTouchStore } from '../../state/touch'
import { Component, LoggedOutNavigationProp } from '../../types'
import CardEditorSettings from '../CardEditorSettings'
import { DragWithPos, useCardEditorState } from './CardEditor.store'
import * as _styles from './CardEditor.styles'
import CardEditorDragList, { CardDraggable } from './CardEditorDragList'
import CardEditorPropertyList from './CardEditorPropertyList'
import CardPreview from './CardPreview'

export type CardEditorProps = ViewProps

const CardEditor: Component<CardEditorProps> = ({ ...others }) => {
  const styles = useStyles(_styles)
  const [bounds, setBounds] = useState({ x: 0, y: 0, width: 0, height: 0 })
  const [rootBounds, setRootBounds] = useState({ x: 0, y: 0, width: 0, height: 0 })
  const previewRef = useRef<View | null>(null)
  const rootRef = useRef<View | null>(null)
  const state = useCardEditorState()
  const { storage } = useMisuStorage()
  const toast = useToast()
  const navigation = useNavigation<LoggedOutNavigationProp>()

  const createCardType = () => {
    Alert.prompt('Create the card type', 'Enter the name for your card type', async (name) => {
      const noteTypeId = nanoid()
      try {
        await storage?.noteType.create(
          new NoteType({
            question_format: JSON.stringify(serializeCardType(state.frontState)),
            answer_format: JSON.stringify(serializeCardType(state.backState)),
            created_at: Date.now(),
            css: '',
            id: noteTypeId,
            name,
            type: 0,
            updated_at: Date.now()
          })
        )
        for (const field of state.fields) {
          await storage?.field.create(
            new Field({
              created_at: Date.now(),
              description: field.description,
              font: 'Arial',
              id: nanoid(),
              name: field.name,
              note_type_id: noteTypeId,
              ordinal: 0,
              size: 0,
              updated_at: Date.now()
            })
          )
        }
        toast.show('Successfully created card type', { type: 'success' })
        navigation.getState().routeNames.includes('user')
          ? navigation.popToTop()
          : navigation.replace('user')
      } catch (err) {
        toast.show('Error creating card type', { type: 'danger' })
      }
    })
  }

  return (
    <View
      {...others}
      style={[styles.container, others.style]}
      ref={(el) => (rootRef.current = el)}
      onLayout={() =>
        rootRef.current?.measure((x, y, w, h, px, py) => {
          setRootBounds({
            x: px,
            y: py,
            width: w,
            height: h
          })
        })
      }
    >
      <DraggedDraggable rootBounds={rootBounds} />
      <View style={styles.previewContainer}>
        <CardPreview
          ref={(el) => (previewRef.current = el)}
          onLayout={() =>
            previewRef.current?.measure((x, y, w, h, px, py) => {
              setBounds({
                x: x,
                y: py,
                height: h,
                width: w
              })
            })
          }
        />
      </View>
      {state.selected ? (
        <CardEditorPropertyList key="prop-list" cardComponent={state.selected} />
      ) : state.settingsOpen ? (
        <CardEditorSettings key="settings" onCreate={createCardType} />
      ) : (
        <CardEditorDragList key="drag-list" previewBounds={bounds} />
      )}
    </View>
  )
}

export default CardEditor

export type DraggedDraggableProps = {
  rootBounds: { x: number; y: number; width: number; height: number }
}

export const DraggedDraggable: Component<DraggedDraggableProps> = ({ rootBounds }) => {
  const styles = useStyles(_styles)
  const state = useCardEditorState()
  const touchState = useTouchStore()
  const [lastDraggable, setLastDraggable] = useState<DragWithPos>({
    x: 0,
    y: 0,
    cardComponent: cardComponents[0]
  })

  useEffect(() => {
    if (!state.currentDrag) return
    setLastDraggable(state.currentDrag)
  }, [state.currentDrag?.x, state.currentDrag?.y])

  useEffect(() => {
    touchState.addListener(() => {
      state.setCurrentDrag(null)
    })
  }, [])

  return (
    <Animatable.View
      style={[
        styles.draggingContainer,
        {
          top: lastDraggable.y - (Platform.OS === 'web' ? rootBounds.y : 64),
          left: lastDraggable.x - (Platform.OS === 'web' ? rootBounds.x : 0),
          opacity: state.currentDrag ? 1 : 0,
          pointerEvents: 'none'
        }
      ]}
    >
      <CardDraggable cardComponent={lastDraggable.cardComponent} canDrag={false} />
    </Animatable.View>
  )
}
