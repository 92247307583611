import { Kysely } from 'kysely'
import { AnkiField } from '../../../anki/field/types'
import { DatabaseSchema15 as AnkiDatabase } from '../../../anki/types'
import { FieldFactory as MisuFieldFactory } from '../../field/factories'
import { Field } from '../../field/models'
import { MisuStorage } from '../../storage/models'

export class FieldConverter {
  private misuDb: MisuStorage
  private ankiDb: Kysely<AnkiDatabase>
  private noteTypeOrdMap: Record<string, Record<number, string>>

  constructor(misuDb: MisuStorage, ankiDb: Kysely<AnkiDatabase>) {
    this.misuDb = misuDb
    this.ankiDb = ankiDb
    this.noteTypeOrdMap = {}
  }

  async insert(misuFieldsBatch: Field[]) {
    await this.misuDb.field.createMultiple(misuFieldsBatch)
  }

  private async processBatch(
    batch: AnkiField[],
    noteTypeIdMap: Record<number, string>
  ): Promise<void> {
    const misuFieldsBatch: Field[] = []
    for (const field of batch) {
      const misuField = MisuFieldFactory.createFromAnkiDeck(field, noteTypeIdMap)
      if (!this.noteTypeOrdMap[misuField.noteTypeId]) {
        this.noteTypeOrdMap[misuField.noteTypeId] = {}
      }
      this.noteTypeOrdMap[misuField.noteTypeId][field.ord] = misuField.id
      misuFieldsBatch.push(misuField)
    }
    await this.insert(misuFieldsBatch)
  }

  async convert(
    noteTypeIdMap: Record<number, string>
  ): Promise<Record<string, Record<number, string>>> {
    const batchSize = 500
    const ankiFields = await this.ankiDb.selectFrom('fields').selectAll().execute()
    for (let i = 0; i < ankiFields.length; i += batchSize) {
      const batch = ankiFields.slice(i, i + batchSize)
      await this.processBatch(batch, noteTypeIdMap)
    }
    return this.noteTypeOrdMap
  }
}
