export * from './Audio'
export * from './TextBox'
export * from './Image'

import { CardComponent } from '../CardComponent'
import Audio from './Audio'
import Image from './Image'
import TextBox from './TextBox'

const _cardComponents = [Audio, TextBox, Image] as const

type Writeable<T> = { -readonly [P in keyof T]: T[P] }

export const cardComponents = _cardComponents as unknown as Writeable<typeof _cardComponents>
export type CardComponentClassType = (typeof cardComponents)[number]

export const getCardComponentClassFromInstance = (
  instance: CardComponent
): CardComponentClassType => {
  for (const component of _cardComponents) {
    if (instance instanceof component) return component
  }
  return null as any
}

export const getCardComponentClassFromKey = (key: string): CardComponentClassType => {
  const comp = cardComponents.find((comp) => comp.key === key)
  if (!comp) throw "Can't find component of key " + key
  return comp
}
