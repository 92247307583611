import { Kysely } from 'kysely'
import { AnkiNote } from '../../../anki/note/types'
import { DatabaseSchema15 as AnkiDatabase } from '../../../anki/types'
import { FieldValue } from '../../field-value/models'
import { NoteFactory as MisuNoteFactory } from '../../note/factories'
import { Note } from '../../note/models'
import { MisuStorage } from '../../storage/models'
import { FieldValueConverter } from './field-value'

export class NoteConverter {
  private misuDb: MisuStorage
  private ankiDb: Kysely<AnkiDatabase>

  constructor(misuDb: MisuStorage, ankiDb: Kysely<AnkiDatabase>) {
    this.misuDb = misuDb
    this.ankiDb = ankiDb
  }

  async insert(misuNotesBatch: Note[]) {
    await this.misuDb.note.createMultiple(misuNotesBatch)
  }

  private async processBatch(
    batch: AnkiNote[],
    noteTypeIdMap: Record<number, string>,
    noteTypeOrdMap: Record<string, Record<number, string>>
  ): Promise<Record<number, string>> {
    const noteIdMap: Record<number, string> = {}
    const misuNotesBatch: Note[] = []
    let fieldValuesBatch: FieldValue[] = []
    const fieldConverter = new FieldValueConverter(this.misuDb)

    for (const note of batch) {
      const noteTypeId = noteTypeIdMap[note.mid]
      const misuNote = MisuNoteFactory.create({ note_type_id: noteTypeId })
      misuNotesBatch.push(misuNote)
      const fieldValues = note.flds.split('\x1f')
      fieldValuesBatch = [
        ...fieldValuesBatch,
        ...fieldConverter.convert(misuNote.id, noteTypeId, fieldValues, noteTypeOrdMap)
      ]
      if (!note.id) {
        throw new Error('Note ID unexpectedly null')
      }
      noteIdMap[note.id] = misuNote.id
    }
    await this.insert(misuNotesBatch)
    await fieldConverter.insert(fieldValuesBatch)
    return noteIdMap
  }

  async convert(
    noteTypeIdMap: Record<number, string>,
    noteTypeOrdMap: Record<string, Record<number, string>>
  ): Promise<Record<number, string>> {
    const ankiNotes = await this.ankiDb.selectFrom('notes').selectAll().execute()
    let noteIdMap: Record<number, string> = {}
    const batchSize = 250
    for (let i = 0; i < ankiNotes.length; i += batchSize) {
      const batch = ankiNotes.slice(i, i + batchSize)
      noteIdMap = {
        ...noteIdMap,
        ...(await this.processBatch(batch, noteTypeIdMap, noteTypeOrdMap))
      }
    }
    return noteIdMap
  }
}
