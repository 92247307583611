import React, { useState } from 'react'
import { Modal, TouchableOpacity, Text, ViewProps, View, ScrollView } from 'react-native'
import { useStyles } from '../../hooks'

import CheckboxFilledIcon from '../../svg/CheckboxFilled'
import CheckboxOutlineIcon from '../../svg/CheckboxOutline'
import ChevronDown from '../../svg/ChevronDown'
import TickIcon from '../../svg/Tick'
import * as _styles from './Select.styles'

export type SelectProps<V> = ViewProps & {
  options: Readonly<{ label: string; value: V }[]>
  placeholder?: string
} & (
    | {
        multiple: true
        value: V[]
        onValueChange: (newValue: V[]) => void
      }
    | {
        multiple?: false
        value: V | null
        onValueChange: (newValue: V) => void
      }
  )

const Select = <V,>({
  value,
  multiple,
  onValueChange,
  placeholder,
  options,
  ...others
}: SelectProps<V>) => {
  const styles = useStyles(_styles)
  const [selectModalOpen, setSelectModalOpen] = useState(false)
  const selectedOptions = options.filter((option) => {
    if (multiple) {
      return value.includes(option.value)
    }
    return option.value === value
  })

  return (
    <>
      <TouchableOpacity
        {...others}
        style={[styles.container, others.style]}
        onPress={() => setSelectModalOpen(true)}
      >
        <Text style={[styles.valueText, selectedOptions.length === 0 && styles.placeholderText]}>
          {selectedOptions.length > 0
            ? selectedOptions.map((option) => option.label).join(', ')
            : placeholder ?? 'Nothing selected'}
        </Text>
        <ChevronDown style={styles.icon} />
      </TouchableOpacity>
      <Modal
        visible={selectModalOpen}
        onRequestClose={() => setSelectModalOpen(false)}
        style={styles.modal}
        animationType="fade"
        transparent
      >
        <TouchableOpacity
          activeOpacity={0.5}
          style={styles.modalBackdrop}
          onPress={() => setSelectModalOpen(false)}
        />
        <View style={styles.modal}>
          <ScrollView contentContainerStyle={styles.optionsContainer}>
            {options.map((option, i) => {
              const selected = multiple ? value.includes(option.value) : value === option.value
              return (
                <TouchableOpacity
                  activeOpacity={0.8}
                  style={[styles.option, selected && styles.optionSelected]}
                  key={i}
                  onPress={() => {
                    if (multiple) {
                      onValueChange(
                        value.includes(option.value)
                          ? value.filter((val) => val !== value)
                          : [...value, option.value]
                      )
                    } else {
                      onValueChange(option.value)
                    }
                    setSelectModalOpen(false)
                  }}
                >
                  {multiple ? (
                    selected ? (
                      <CheckboxFilledIcon style={styles.checkIcon} />
                    ) : (
                      <CheckboxOutlineIcon style={styles.checkIcon} />
                    )
                  ) : (
                    selected && <TickIcon style={styles.tickIcon} />
                  )}
                  <Text style={styles.optionText}>{option.label}</Text>
                </TouchableOpacity>
              )
            })}
          </ScrollView>
        </View>
      </Modal>
    </>
  )
}

export default Select
