import { useCallback, useEffect, useState } from 'react'
import { Deck } from '../../../packages/misu/deck/models'
import { DeckType } from '../../../packages/misu/deck/types'
import { useMisuStorage } from './useMisuStorage'

export type UseDeckResponse = {
  deck: Deck | null
  fetching: boolean
  error: unknown
  mutate: (opts: Partial<DeckType>) => void
  update: (opts: Partial<DeckType>) => void
}

export const useDeck = (deckId: string | null | undefined): UseDeckResponse => {
  const [deck, setDeck] = useState<Deck | null>(null)
  const [fetching, setFetching] = useState(true)
  const [error, setError] = useState<unknown>(null)
  const { storage } = useMisuStorage()

  const getDeck = async (unmounted: { current: boolean }) => {
    if (!storage || !deckId) return
    try {
      setFetching(true)
      const deck = await storage.deck.get(deckId)
      if (unmounted.current) return
      setDeck(deck)
    } catch (err) {
      if (unmounted.current) return
      setError(err)
    }
    setFetching(false)
  }

  useEffect(() => {
    let unmounted = { current: false }
    getDeck(unmounted)
    return () => {
      unmounted.current = true
    }
  }, [deckId, storage])

  const update = useCallback(
    (opts: Partial<DeckType>) => {
      if (!storage || !deckId) return
      storage.deck.update(deckId, opts).then(setDeck)
    },
    [storage, deckId]
  )

  return {
    deck,
    fetching,
    error,
    mutate: (opts) => {
      if (!deck) return
      setDeck((prev) => {
        if (!prev) return null
        return new Deck({
          id: deck.id ?? opts.id,
          config_id: deck.configId,
          created_at: deck.createdAt,
          description: deck.description,
          name: deck.name,
          updated_at: deck.updatedAt,
          ...opts
        })
      })
    },
    update
  }
}
