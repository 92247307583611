import { useEffect, useState } from 'react'
import { Field } from '../../../packages/misu/field/models'
import { useMisuStorage } from './useMisuStorage'

export type UseFieldsResponse = {
  fields: Field[] | null
  fetching: boolean
  error: unknown
}

export type UseFieldsArgs =
  | {
      noteTypeId: string | null
      noteId?: undefined
    }
  | {
      noteId: string | null
      noteTypeId?: undefined
    }

export const useFields = (args: UseFieldsArgs) => {
  const [fields, setFields] = useState<Field[] | null>(null)
  const [fetching, setFetching] = useState(true)
  const [error, setError] = useState<unknown>(null)
  const { storage } = useMisuStorage()

  const getFields = async (unmountedRef: { current: boolean }) => {
    if (!storage) return
    if (!args.noteId && !args.noteTypeId) return
    try {
      let noteTypeId: string | null | undefined
      if (args.noteId) {
        const note = await storage.note.get(args.noteId)
        noteTypeId = note.noteTypeId
      } else noteTypeId = args.noteTypeId
      if (!noteTypeId || unmountedRef.current) return
      const fields = await storage.field.getForNoteType(noteTypeId)
      if (unmountedRef.current) return
      setFields(fields)
      setFetching(false)
    } catch (err) {
      if (unmountedRef.current) return
      setFetching(false)
      setError(err)
    }
  }

  useEffect(() => {
    let unmountedRef = { current: false }
    getFields(unmountedRef)
    return () => {
      unmountedRef.current = true
    }
  }, [storage, args.noteId, args.noteTypeId])

  return {
    fields,
    fetching,
    error
  }
}
