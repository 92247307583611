import { TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../../../themes'

export const container = ({}: Theme): ViewStyle => ({
  flexDirection: 'row',
  gap: 12,
  alignItems: 'center'
})

export const slider = (): ViewStyle => ({
  flex: 1
})

export const text = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textMain
})
