import { create } from 'zustand'
import { CardComponent, cardComponents } from '../../classes/CardRendering'

export type DragWithPos = {
  x: number
  y: number
  cardComponent: (typeof cardComponents)[number]
}

export type CardEditorField = {
  name: string
  value: string
  description: string
}

export type CardEditorStore = {
  currentDrag: DragWithPos | null
  setCurrentDrag: (item: DragWithPos | null) => void
  cardState: CardComponent[]
  setCardState: (newState: CardComponent[]) => void
  frontState: CardComponent[]
  backState: CardComponent[]
  settingsOpen: boolean
  setSettingsOpen: (open: boolean) => void
  preview: boolean
  setPreview: (preview: boolean) => void
  direction: 'front' | 'back'
  setDirection: (dir: 'front' | 'back') => void
  selected: CardComponent | null
  setSelected: (comp: CardComponent | null) => void
  fields: CardEditorField[]
  setFields: (fields: CardEditorField[]) => void
}

export const useCardEditorState = create<CardEditorStore>((set, getState) => ({
  currentDrag: null,
  setCurrentDrag: (item) => set({ currentDrag: item }),
  cardState: [],
  frontState: [],
  backState: [],
  setCardState: (newState) => {
    const state = getState()
    if (state.selected && !newState.includes(state.selected)) {
      set({ selected: null })
    }
    if (state.direction === 'front') set({ cardState: newState, frontState: newState })
    else set({ cardState: newState, backState: newState })
  },
  settingsOpen: false,
  setSettingsOpen: (open) => set({ settingsOpen: open }),
  preview: false,
  setPreview: (preview) => set({ preview }),
  direction: 'front',
  setDirection: (direction: 'front' | 'back') => {
    const state = getState()
    const prevDirection = state.direction
    if (direction === prevDirection) return
    const cardState = state.cardState
    if (direction === 'front') {
      set({ backState: cardState, cardState: state.frontState, direction })
    } else {
      set({ frontState: cardState, cardState: state.backState, direction })
    }
  },
  selected: null,
  setSelected: (comp) => set({ selected: comp }),
  fields: [],
  setFields: (fields) => set({ fields })
}))
