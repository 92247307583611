import { ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const container = (): ViewStyle => ({
  margin: 4
})

export const handleContainer = (): ViewStyle => ({
  position: 'absolute',
  padding: 8,
  zIndex: 1,
  overflow: 'visible',
  borderRadius: 999,
  pointerEvents: 'auto'
})

export const handleContainer_top = (): ViewStyle => ({
  top: -11,
  left: '50%',
  transform: [{ translateX: -12 }]
})

export const handleContainer_bottom = (): ViewStyle => ({
  bottom: -11,
  left: '50%',
  transform: [{ translateX: -12 }]
})

export const handleContainer_left = (): ViewStyle => ({
  left: -11,
  top: '50%',
  transform: [{ translateY: -12 }]
})

export const handleContainer_right = (): ViewStyle => ({
  right: -11,
  top: '50%',
  transform: [{ translateY: -12 }]
})

export const handleInner = ({ colors }: Theme): ViewStyle => ({
  height: 8,
  width: 8,
  backgroundColor: colors.primary.main,
  borderRadius: 99
})
