import { StackScreenProps } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, ViewProps, Text, ScrollView } from 'react-native'
import { useToast } from 'react-native-toast-notifications'
import { CardFactory } from '../../../packages/misu/card/factories'
import { Card } from '../../../packages/misu/card/models'
import { FieldValue } from '../../../packages/misu/field-value/models'
import { Note } from '../../../packages/misu/note/models'
import { nanoid, stringToSHA1EightDigitInteger } from '../../../packages/misu/utils'
import { Button, FormLabel, Input } from '../../components'
import ConfirmationModal from '../../components/ConfirmationModal'
import IconButton from '../../components/IconButton'
import Modal, { ModalProps } from '../../components/Modal'
import Screen from '../../components/Screen'
import Select from '../../components/Select'
import {
  useDeckCards,
  useFieldValues,
  useFields,
  useMisuStorage,
  useNoteTypes,
  useStyles
} from '../../hooks'
import { CloseIcon } from '../../svg'
import { Component, LoggedOutParamsList } from '../../types'
import * as _styles from './EditDeckCardsScreen.styles'

export type EditDeckCardsScreenProps = ViewProps &
  StackScreenProps<LoggedOutParamsList, 'edit-deck-cards'>

const EditDeckCardsScreen: Component<EditDeckCardsScreenProps> = ({ route, ...others }) => {
  const styles = useStyles(_styles)
  const { cards, refetch, mutate } = useDeckCards(route.params?.deckId)
  const { storage } = useMisuStorage()
  const [t] = useTranslation('edit-deck')
  const [addCardModalOpen, setAddCardModalOpen] = useState(false)
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false)
  const [cardToDelete, setCardToDelete] = useState<Card | null>(null)
  const toast = useToast()

  const addCard: AddCardModalProps['onAddCard'] = async ({ selectedNoteTypeId, fieldValues }) => {
    if (!storage || !route.params.deckId) return
    try {
      const noteId = nanoid()
      await storage.note.create(
        new Note({
          created_at: Date.now(),
          id: noteId,
          note_type_id: selectedNoteTypeId,
          updated_at: Date.now()
        })
      )
      // No idea how im actually supposed to create a card
      await storage.card.create(
        CardFactory.create({
          deck_id: route.params.deckId,
          fsrs_difficulty: null,
          fsrs_stability: null,
          due: 0,
          interval: 0,
          lapses: 0,
          left: 0,
          note_id: noteId,
          ordinal: 0,
          reps: 0,
          type: 0
        })
      )
      for (const [fieldId, fieldValue] of Object.entries(fieldValues)) {
        const checksum = stringToSHA1EightDigitInteger(fieldValue)
        await storage.fieldValue.create(
          new FieldValue({
            id: nanoid(),
            created_at: Date.now(),
            field_id: fieldId,
            note_id: noteId,
            updated_at: Date.now(),
            value: fieldValue,
            value_checksum: checksum
          })
        )
      }
      await refetch()
      setAddCardModalOpen(false)
      toast.show('Successfully added card', { type: 'success' })
    } catch (err) {
      console.error(err)
      toast.show('Error adding card', { type: 'danger' })
    }
  }

  const deleteCard = async () => {
    if (!cardToDelete || !storage) return
    try {
      await storage.card.delete(cardToDelete.id)
      mutate(cards?.filter((card) => card.id !== cardToDelete.id) ?? [])
      toast.show('Successfully deleted card', { type: 'success' })
    } catch (err) {
      toast.show('Error deleting card', { type: 'danger' })
    }
  }

  return (
    <Screen>
      <View {...others} style={[styles.container, others.style]}>
        <Text style={styles.heading}>{t('headings.cards')}</Text>
        <ScrollView contentContainerStyle={{ gap: 8 }}>
          {(cards?.length ?? 0) === 0 ? (
            <Text style={styles.emptyText}>No cards in deck</Text>
          ) : (
            (cards ?? []).map((card) => (
              <CardItemPreview
                key={card.id}
                card={card}
                onDelete={() => {
                  setCardToDelete(card)
                  setDeleteConfirmationModalOpen(true)
                }}
              />
            ))
          )}
        </ScrollView>
        <Button style={{ marginTop: 'auto' }} onPress={() => setAddCardModalOpen(true)}>
          {t('button.add-card')}
        </Button>
        <AddCardModal
          open={addCardModalOpen}
          onClose={() => setAddCardModalOpen(false)}
          onAddCard={addCard}
        />
        <ConfirmationModal
          buttonText="Delete"
          confirmationText="Are you sure you want to delete this card?"
          onClose={() => setDeleteConfirmationModalOpen(false)}
          onConfirm={() => deleteCard()}
          open={deleteConfirmationModalOpen}
        />
      </View>
    </Screen>
  )
}

export default EditDeckCardsScreen

export type CardItemPreviewProps = {
  card: Card
  onDelete?: () => void
}

export const CardItemPreview: Component<CardItemPreviewProps> = ({ card, onDelete }) => {
  const styles = useStyles(_styles)
  const { fields } = useFields({ noteId: card.noteId })
  const { fieldValues } = useFieldValues({ noteId: card.noteId })

  const field = fields?.[0]
  const fieldValue = fieldValues?.find((fieldValue) => fieldValue.fieldId === field?.id)

  return (
    <View style={styles.cardPreviewContainer}>
      <Text style={styles.cardPreviewText}>
        {field?.name}: "{fieldValue?.value}"
      </Text>
      <IconButton
        style={styles.cardPreviewDeleteButton}
        icon={CloseIcon}
        onPress={() => onDelete?.()}
      />
    </View>
  )
}

export type AddCardModalProps = ModalProps & {
  onAddCard: (args: {
    selectedNoteTypeId: string
    fieldValues: Record<string, string>
  }) => Promise<void>
}

export const AddCardModal: Component<AddCardModalProps> = ({ onAddCard, ...others }) => {
  const styles = useStyles(_styles)
  const [t] = useTranslation('edit-deck')
  const { noteTypes } = useNoteTypes()
  const [selectedNoteTypeId, setSelectedNoteTypeId] = useState<string | null>(null)

  const { fields: selectedFields } = useFields({ noteTypeId: selectedNoteTypeId })

  const [fieldValues, setFieldValues] = useState<Record<string, string>>({})

  const getDefaultFieldValues = () => {
    if (!selectedFields) return {}
    let vals: Record<string, string> = {}
    selectedFields.forEach((field) => {
      vals[field.id] = ''
    })
    return vals
  }

  useEffect(() => {
    setFieldValues(getDefaultFieldValues)
  }, [selectedFields])

  return (
    <Modal {...others} title={t('modals.add-card.title')} style={styles.addCardModal}>
      <FormLabel label="Card Type">
        <Select
          options={
            noteTypes?.map((noteType) => ({
              label: noteType.name,
              value: noteType.id
            })) ?? []
          }
          placeholder="Select a card type"
          value={selectedNoteTypeId}
          onValueChange={(newVal) => setSelectedNoteTypeId(newVal)}
        />
      </FormLabel>
      <Text style={styles.fieldHeading}>Fields</Text>
      <ScrollView style={styles.fieldScrollView} bounces={false}>
        <View style={styles.fieldContainer}>
          {selectedFields?.map((field) => (
            <View key={field.id} style={styles.field}>
              <Text style={styles.fieldText}>{field.name}</Text>
              <Input
                value={fieldValues[field.id]}
                onChangeText={(newText) =>
                  setFieldValues((oldVals) => ({
                    ...oldVals,
                    [field.id]: newText
                  }))
                }
                style={styles.fieldInput}
                placeholder="Enter Field Value"
              />
            </View>
          ))}
        </View>
      </ScrollView>
      <Button
        size="compact"
        style={styles.closeButton}
        onPress={() => {
          if (!selectedNoteTypeId) return
          onAddCard({ selectedNoteTypeId, fieldValues })
          setFieldValues(getDefaultFieldValues())
        }}
      >
        {t('button.add-card')}
      </Button>
    </Modal>
  )
}
