import { Kysely } from 'kysely'
import { SQLiteDatabase, SQLiteDatabaseHandler } from '../interfaces/sqlite/types'
import { SqliteDialect } from '../kysely-sqlite-generic'
import { nanoid } from '../misu/utils'
import { DatabaseSchema11 } from './types'

export const initAnkiDatabase = async (
  collectionPath: string,
  dbHandler: SQLiteDatabaseHandler
): Promise<[Kysely<DatabaseSchema11>, SQLiteDatabase]> => {
  const ankiDbName = `anki-import-${nanoid(6)}`
  await dbHandler.delete(ankiDbName).catch(() => {})
  const sqliteDatabase = await dbHandler.create(ankiDbName, {
    createFromLocation: collectionPath
  })

  await sqliteDatabase.executeSql('PRAGMA journal_mode = WAL')
  await sqliteDatabase.executeSql('PRAGMA legacy_file_format = false')
  // await sqliteDatabase.executeSql('PRAGMA locking_mode = exclusive')
  await sqliteDatabase.executeSql('PRAGMA page_size = 4096')
  await sqliteDatabase.executeSql(`PRAGMA cache_size = ${-40 * 1024}`)

  if (process.platform === 'android') {
    await sqliteDatabase.executeSql('PRAGMA temp_store = memory')
  }
  const kyselyWrapper = new Kysely<DatabaseSchema11>({
    dialect: new SqliteDialect({
      database: sqliteDatabase
    })
  })
  return [kyselyWrapper, sqliteDatabase]
}
