import React from 'react'
import { Text, View, ViewProps } from 'react-native'
import { useTheme } from '../../state/theme'

export type FormLabelProps = ViewProps & {
  children?: JSX.Element | JSX.Element[]
  label: string
}

const FormLabel = ({ children, label, ...others }: FormLabelProps) => {
  const { fonts } = useTheme()

  return (
    <View {...others}>
      <Text style={[fonts.label, { marginBottom: 8 }]}>{label}</Text>
      {children}
    </View>
  )
}

export default FormLabel
