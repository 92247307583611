import { useEffect, useState } from 'react'
import { FieldValue } from '../../../packages/misu/field-value/models'
import { useMisuStorage } from './useMisuStorage'

export type UseFieldValuesResponse = {
  fieldValues: FieldValue[] | null
  fetching: boolean
  error: unknown
}

export type UseFieldValuesArgs =
  | {
      cardId: string | null
      noteId?: undefined
    }
  | {
      noteId: string | null
      cardId?: undefined
    }

export const useFieldValues = (args: UseFieldValuesArgs): UseFieldValuesResponse => {
  const [fieldValues, setFieldValues] = useState<FieldValue[] | null>(null)
  const [fetching, setFetching] = useState(true)
  const [error, setError] = useState<unknown>(null)
  const { storage } = useMisuStorage()

  const getFieldValues = async (unmountedRef: { current: boolean }) => {
    if (!storage) return
    try {
      let noteId: string | null
      if (args.cardId) {
        const card = await storage.card.get(args.cardId)
        noteId = card.noteId
      } else if (args.noteId) noteId = args.noteId
      else return
      const fieldValues = await storage.fieldValue.getForNote(noteId)
      if (unmountedRef.current) return
      setFieldValues(fieldValues)
      setFetching(false)
    } catch (err) {
      if (unmountedRef.current) return
      setFetching(false)
      setError(err)
    }
  }

  useEffect(() => {
    let unmountedRef = { current: false }
    getFieldValues(unmountedRef)
    return () => {
      unmountedRef.current = true
    }
  }, [args.cardId, args.noteId, storage])

  return {
    fieldValues,
    fetching,
    error
  }
}
