import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native'
import React from 'react'
import { SafeAreaView, StatusBar } from 'react-native'
import { ToastProvider } from 'react-native-toast-notifications'
import { useCSSVariable } from '../hooks'
import { Startup } from '../screens'
import { useIsLoaded } from '../state/loaded'
import { useTheme } from '../state/theme'
import LoggedOut from './LoggedOut'

// @refresh reset
const ApplicationNavigator = () => {
  const theme = useTheme()

  useCSSVariable('body-background', theme.background.web)
  useCSSVariable('color-scheme', theme.dark ? 'dark' : 'light')
  useCSSVariable('scroll-thumb', theme.background.contrast)
  useCSSVariable('scroll-bg', theme.background.default)

  const { navigationTheme, dark } = theme
  const { isLoaded } = useIsLoaded()

  const navigationRef = useNavigationContainerRef()

  return (
    <SafeAreaView style={[{ flex: 1, backgroundColor: theme.background.default }]}>
      <ToastProvider duration={3000} style={{ pointerEvents: 'none' }}>
        <NavigationContainer
          theme={navigationTheme}
          ref={navigationRef}
          linking={{
            prefixes: ['https://app.misu.io', 'misu://app.misu.io', 'misu://'],
            enabled: true
          }}
        >
          <StatusBar barStyle={dark ? 'light-content' : 'dark-content'} />
          {isLoaded ? <LoggedOut /> : <Startup />}
        </NavigationContainer>
      </ToastProvider>
    </SafeAreaView>
  )
}

export default ApplicationNavigator
