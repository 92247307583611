import { Kysely } from 'kysely'
import { AnkiTemplate } from '../../../anki/template/types'
import { DatabaseSchema15 as AnkiDatabase } from '../../../anki/types'
import { MisuStorage } from '../../storage/models'
import { TemplateFactory as MisuTemplateFactory } from '../../template/factories'
import { Template } from '../../template/models'

export class TemplateConverter {
  private misuDb: MisuStorage
  private ankiDb: Kysely<AnkiDatabase>

  constructor(misuDb: MisuStorage, ankiDb: Kysely<AnkiDatabase>) {
    this.misuDb = misuDb
    this.ankiDb = ankiDb
  }

  async insert(misuTemplatesBatch: Template[]) {
    await this.misuDb.template.createMultiple(misuTemplatesBatch)
  }

  private async processBatch(
    batch: AnkiTemplate[],
    noteTypeIdMap: Record<number, string>
  ): Promise<void> {
    const misuTemplatesBatch: Template[] = []
    for (const template of batch) {
      const misuTemplate = MisuTemplateFactory.createFromAnkiDeck(template, noteTypeIdMap)
      misuTemplatesBatch.push(misuTemplate)
    }
    await this.insert(misuTemplatesBatch)
  }

  async convert(noteTypeIdMap: Record<number, string>): Promise<void> {
    const batchSize = 500
    const ankiTemplates = await this.ankiDb.selectFrom('templates').selectAll().execute()
    for (let i = 0; i < ankiTemplates.length; i += batchSize) {
      const batch = ankiTemplates.slice(i, i + batchSize)
      await this.processBatch(batch, noteTypeIdMap)
    }
  }
}
