import { TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../../../themes'

export const container = ({}: Theme): ViewStyle => ({
  flexDirection: 'row',
  gap: 2.5
})

export const button = ({ colors }: Theme): ViewStyle => ({
  flex: 1,
  borderRadius: 0,
  borderWidth: 0,
  borderColor: colors.component.background,
  height: 34
})

export const buttonText = (): TextStyle => ({
  fontSize: 17
})

export const firstButton = ({ radius }: Theme): ViewStyle => ({
  borderTopLeftRadius: radius.button / 1.5,
  borderBottomLeftRadius: radius.button / 1.5
})

export const lastButton = ({ radius }: Theme): ViewStyle => ({
  borderTopRightRadius: radius.button / 1.5,
  borderBottomRightRadius: radius.button / 1.5,
  borderRightWidth: 0
})
