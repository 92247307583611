import { TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const container = ({ background }: Theme): ViewStyle => ({
  height: 300,
  backgroundColor: background.card,
  gap: 12
})

export const heading = ({ fonts, background }: Theme): TextStyle => ({
  backgroundColor: background.cardLight,
  ...fonts.titleRegular,
  fontSize: 20,
  padding: 8
})

export const closeButton = (): ViewStyle => ({
  position: 'absolute',
  top: -4,
  right: 0
})

export const cardInner = (): ViewStyle => ({
  padding: 8,
  paddingVertical: 12,
  gap: 16
})

export const emptyFieldText = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textSecondary
})

export const fieldProperty = (): ViewStyle => ({
  gap: 12
})

export const fieldContainer = (): ViewStyle => ({
  flexDirection: 'row',
  gap: 8
})

export const fieldInput = ({ fonts, colors }: Theme): TextStyle => ({
  ...fonts.textMain,
  paddingHorizontal: 12,
  paddingVertical: 8,
  borderRadius: 8,
  borderWidth: 1,
  borderColor: colors.input.border,
  backgroundColor: colors.input.background,
  flex: 1
})
