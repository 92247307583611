import { useCallback, useEffect, useMemo, useState } from 'react'
import { DeckType, DueCountsQuery } from '../../../packages/misu/deck/types'
import { useIsUnmounted } from '../useIsUnmounted'
import { useMisuStorage } from './useMisuStorage'

export type CombinedDeck = DeckType & { dueCounts: DueCountsQuery }
export type UseDecksResponse = {
  decks: DeckType[]
  combinedDecks: CombinedDeck[]
  fetching: boolean
  refetch: (doesSetFetching: boolean) => void
  mutateDecks: (deckFunc: (old: DeckType[]) => DeckType[]) => void
}

export const useDecks = (): UseDecksResponse => {
  const { storage, collection } = useMisuStorage()
  const isUnmounted = useIsUnmounted()
  const [decks, setDecks] = useState<DeckType[]>([])
  const [dueCounts, setDueCounts] = useState<DueCountsQuery[]>([])
  const [fetchingDecks, setFetchingDecks] = useState(true)
  const [fetchingDueCounts, setFetchingDueCounts] = useState(true)

  const combinedDecks = useMemo<CombinedDeck[]>(() => {
    let deckMap: Record<string, CombinedDeck> = {}
    let newDecks = decks.map((deck) => {
      const newDeck: CombinedDeck = {
        ...deck,
        dueCounts: {
          id: deck.id,
          learning: 0,
          new: 0,
          review: 0,
          total: 0
        }
      }
      deckMap[deck.id] = newDeck
      return newDeck
    })

    for (const dueCount of dueCounts) {
      let deck = deckMap[dueCount.id]
      if (!deck) continue
      deck.dueCounts = dueCount
    }

    return newDecks
  }, [decks, dueCounts])

  const getDecks = useCallback(
    async (unmounted: { current: boolean }, doesSetFetching = true) => {
      if (!storage) return
      if (doesSetFetching) setFetchingDecks(true)
      const decks = await storage.deck.getAll()
      if (unmounted.current) return
      setDecks(decks.map((deck) => deck.toDTO()))
      if (doesSetFetching) setFetchingDecks(false)
    },
    [storage]
  )

  const getDueCounts = useCallback(
    async (unmounted: { current: boolean }, doesSetFetching = true) => {
      if (!storage || !collection) return
      if (doesSetFetching) setFetchingDueCounts(true)
      const dueCounts = await collection.getDeckDueCountsToday()
      if (unmounted.current) return
      if (doesSetFetching) setFetchingDueCounts(false)
      setDueCounts(dueCounts)
    },
    [storage, collection]
  )

  useEffect(() => {
    let unmounted = { current: false }
    getDecks(unmounted)
    return () => {
      unmounted.current = true
    }
  }, [storage])

  useEffect(() => {
    let unmounted = { current: false }
    getDueCounts(unmounted)
    return () => {
      unmounted.current = true
    }
  }, [storage, collection])

  const refetch = useCallback(
    (doesSetFetching: boolean = true) => {
      getDecks(isUnmounted, doesSetFetching)
      getDueCounts(isUnmounted, doesSetFetching)
    },
    [isUnmounted, getDecks, getDueCounts]
  )

  return {
    combinedDecks,
    decks,
    fetching: fetchingDecks || fetchingDueCounts,
    refetch,
    mutateDecks: (deckFunc: (old: DeckType[]) => DeckType[]) => {
      setDecks(deckFunc(decks))
    }
  }
}
