import { FieldValueFactory as MisuFieldValueFactory } from '../../field-value/factories'
import { FieldValue } from '../../field-value/models'
import { MisuStorage } from '../../storage/models'

export class FieldValueConverter {
  private misuDb: MisuStorage

  constructor(misuDb: MisuStorage) {
    this.misuDb = misuDb
  }

  async insert(fieldValuesBatch: FieldValue[]) {
    await this.misuDb.fieldValue.createMultiple(fieldValuesBatch)
  }

  convert(
    noteId: string,
    noteTypeId: string,
    fieldValues: string[],
    noteTypeOrdMap: Record<string, Record<number, string>>
  ): FieldValue[] {
    const fieldValuesBatch = []
    for (const [index, fieldValue] of fieldValues.entries()) {
      const misuFieldValue = MisuFieldValueFactory.createFromAnkiDeck({
        note_id: noteId,
        field_id: noteTypeOrdMap[noteTypeId][index],
        value: fieldValue
      })
      fieldValuesBatch.push(misuFieldValue)
    }
    return fieldValuesBatch
  }
}
