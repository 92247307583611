import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'
const SvgComponent = (props: SvgProps) => (
  <Svg
    fill="currentColor"
    strokeWidth={0}
    style={{
      overflow: 'visible'
    }}
    viewBox="0 0 512 512"
    {...props}
  >
    <Path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={48}
      d="M368 368 144 144m224 0L144 368"
    />
  </Svg>
)
export default SvgComponent
