import React from 'react'
import { Platform, StyleProp, TextInputProps, View, ViewStyle } from 'react-native'
import { TextInput } from 'react-native-gesture-handler'
import { useStyles } from '../../hooks'
import { useTheme } from '../../state/theme'

import * as _styles from './Input.styles'

export type InputProps = {
  style?: StyleProp<ViewStyle>
  childrenPosition?: 'left' | 'right'
  size?: 'default' | 'compact'
} & Omit<TextInputProps, 'style'>

const Input = ({
  style,
  childrenPosition = 'right',
  size = 'default',
  children,
  ...others
}: InputProps) => {
  const styles = useStyles(_styles)
  const { colors, fonts, shadow } = useTheme()

  return (
    <View style={[styles.input, styles[`input_size_${size}`], shadow.input, style]}>
      {childrenPosition === 'left' && children}
      <TextInput
        {...others}
        style={[
          fonts.textMain,
          {
            borderRadius: 8,
            padding: Platform.OS === 'web' ? 16 : 0,
            fontSize: 18,
            flex: 1,
            minWidth: 0
          }
        ]}
        placeholderTextColor={colors.input.placeholder}
        autoCapitalize={others.autoCapitalize ?? 'none'}
      />
      {childrenPosition === 'right' && children}
    </View>
  )
}

export default Input
