import { Selectable } from 'kysely'
import { ReviewTable } from '../storage/review/tables'

export type ReviewType = Selectable<ReviewTable>
export type CreateReviewType = Omit<ReviewType, 'id' | 'created_at' | 'updated_at' | 'platform'>
export type CreateAnkiReviewType = Omit<ReviewType, 'id'>
export enum Platform {
  Misu = 0,
  Anki = 1
}
