import { MisuRendererTransformer } from './misu-renderer'

export const removeNbspTransformer: MisuRendererTransformer = {
  getTransformTarget: (html: string) => {
    const nbspStr = '&nbsp;'
    const index = html.indexOf(nbspStr)
    if (index === -1) return null
    return {
      str: nbspStr,
      index
    }
  },
  transform(transformTarget, html) {
    html = html.replace('&nbsp;', ' ')
    return html
  },
  setup: undefined
}
