import * as React from 'react'
import Svg, { SvgProps, Path, Rect } from 'react-native-svg'
const SvgComponent = (props: SvgProps) => (
  <Svg viewBox="0 0 512 512" {...props}>
    <Path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={32}
      d="M352 176 217.6 336 160 272"
    />
    <Rect
      width={384}
      height={384}
      x={64}
      y={64}
      fill="none"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={32}
      rx={48}
      ry={48}
    />
  </Svg>
)
export default SvgComponent
