import { getFilesystemHandler } from '../../interfaces/filesystem/generic'
import { FileSystemHandler } from '../../interfaces/filesystem/types'
import { CardRenderData } from '../collection/types'
import { fieldTransformer } from './field-transformer'
import { mediaTransformer } from './media-transformer'
import { removeNbspTransformer } from './remove-nbsp-transformer'
import { rubyTextTransformer } from './ruby-text-transformer'
import { tagRemoverTransformer } from './tag-remover-transformer'

export type MisuRendererArgs = {
  cardRender: CardRenderData
  defaultCss: string
  mediaFolder: string
  defaultJs?: string
}

export type MisuRendererReturnArgs = {
  frontHtml: string
  backHtml: string
}

export type TransformTarget = {
  index: number
  str: string
  type?: string
}

export type MisuRendererTransformerArgs = MisuRendererArgs & {
  frontSideHtml?: string
  fsHandler: FileSystemHandler
}

export type MisuRendererTransformer<T = undefined> = {
  getTransformTarget: (html: string) => TransformTarget | null
  transform: (
    transformTarget: TransformTarget,
    html: string,
    args: MisuRendererTransformerArgs,
    state: T
  ) => string | Promise<string>
  setup: T extends undefined
    ? undefined
    : (html: string, args: MisuRendererTransformerArgs) => T | Promise<T>
}

const transformers: MisuRendererTransformer<any>[] = [
  rubyTextTransformer,
  fieldTransformer,
  mediaTransformer,
  removeNbspTransformer,
  tagRemoverTransformer
]

export class MisuRenderer {
  constructor() {}

  public wrapInDefaultHtml(html: string, css: string, defaultCss: string, defaultJs?: string) {
    return `
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=0.75, maximum-scale=0.75" />
    <style>${defaultCss}</style>
    <style>${css}</style>
    ${defaultJs ? `<script type="text/javascript">${defaultJs}</script>` : ''}
  </head>
  <body>
    ${html}
  </body>
</html>
    `
  }

  public async applyTransformers(
    transformers: MisuRendererTransformer<any>[],
    html: string,
    args: MisuRendererTransformerArgs
  ): Promise<string> {
    for (const transformer of transformers) {
      let target = transformer.getTransformTarget(html)
      const setup = transformer.setup
      let state: unknown
      if (setup) state = await setup(html, args)
      while (target) {
        html = await transformer.transform(target, html, args, state)
        target = transformer.getTransformTarget(html)
      }
    }
    return html
  }

  public async generateHtml(html: string, args: MisuRendererTransformerArgs) {
    const transformedHtml = await this.applyTransformers(transformers, html, args)
    const wrappedHtml = this.wrapInDefaultHtml(
      transformedHtml,
      args.cardRender.noteType.css,
      args.defaultCss,
      args.defaultJs
    )
    return {
      wrappedHtml,
      transformedHtml
    }
  }

  public async generateFullHtml(args: MisuRendererArgs): Promise<MisuRendererReturnArgs> {
    let transformerArgs: MisuRendererTransformerArgs = {
      ...args,
      fsHandler: await getFilesystemHandler()
    }

    const { wrappedHtml: frontHtml, transformedHtml: initialFrontHtml } = await this.generateHtml(
      args.cardRender.noteType.questionFormat,
      transformerArgs
    )
    const { wrappedHtml: backHtml } = await this.generateHtml(
      args.cardRender.noteType.answerFormat,
      {
        ...transformerArgs,
        frontSideHtml: initialFrontHtml
      }
    )
    return {
      frontHtml,
      backHtml
    }
  }
}
