import { Kysely, sql } from 'kysely'
import { arrayBufferToUtf8 } from '../../misu/utils'
import { DatabaseSchema14, DatabaseSchema15 } from '../types'
import { DeckConfigTableSchema11 } from './tables'

export const upgradeDeckConfigToSchema14 = async (db: Kysely<DatabaseSchema14>) => {
  const row = await db.selectFrom('col').select('dconf').executeTakeFirst()
  if (!row) {
    throw new Error('Failed to get deck conf')
  }
  let config: Record<number, DeckConfigTableSchema11>
  try {
    config = JSON.parse(row.dconf)
  } catch (error) {
    // If parsing fails, handle duplicates and try again
    const confValue: any = JSON.parse(row.dconf)
    config = JSON.parse(JSON.stringify(confValue))
  }
  for (const [id, conf] of Object.entries(config)) {
    conf.id = parseInt(id, 10)
    const existingId = await db
      .selectFrom('deck_config')
      .select('id')
      .where('id', '=', conf.id)
      .executeTakeFirst()

    let idToUse = conf.id
    if (existingId) {
      const maxId = await db
        .selectFrom('deck_config')
        .select(sql<number>`max(id) + 1`.as('newId'))
        .executeTakeFirst()
      if (!maxId?.newId) {
        throw new Error('Unable to determine the new ID for deck_config')
      }
      idToUse = maxId.newId
    }
    await db
      .insertInto('deck_config')
      .values({
        id: idToUse,
        name: conf.name,
        mtime_secs: conf.mod,
        usn: conf.usn,
        config: Buffer.from(JSON.stringify(conf))
      })
      .executeTakeFirstOrThrow()
  }
  await db.updateTable('col').set({ dconf: '' }).execute()
}

export const upgradeDeckConfigToSchema15 = async (db: Kysely<DatabaseSchema15>) => {
  const rows = await db.selectFrom('deck_config').select('config').execute()
  const parsedRows = rows.map((row) => JSON.parse(arrayBufferToUtf8(row.config)))
  for (const config of parsedRows) {
    config.new.initialFactor *= 100
    await db
      .updateTable('deck_config')
      .set({ config: Buffer.from(JSON.stringify(config)) })
      .where('id', '=', config.id)
      .execute()
  }
}

export const upgradeDeckConfigToSchema16 = async (db: Kysely<DatabaseSchema15>) => {
  const rows = await db.selectFrom('deck_config').select('config').execute()
  const parsedRows = rows.map((row) => JSON.parse(arrayBufferToUtf8(row.config)))
  const invalidConfigs: number[] = []
  for (const config of parsedRows) {
    config.new.initialFactor /= 100
    if (config.new.initialFactor <= 1300) {
      config.new.initialFactor = 2500
      invalidConfigs.push(config.id)
    }
    await db
      .updateTable('deck_config')
      .set({ config: Buffer.from(JSON.stringify(config)) })
      .where('id', '=', config.id)
      .execute()
  }
  const decks = await db.selectFrom('decks').select(['id', 'name']).execute()
  const parsedDecks = decks.map((deck) => ({
    id: deck.id,
    name: deck.name.replace('\x1f', '::')
  }))
  let affectedDecks: number[] = []
  for (const conf of parsedRows) {
    for (const { id: deckId } of parsedDecks) {
      const deck = await db
        .selectFrom('decks')
        .selectAll()
        .where('decks.id', '=', deckId)
        .executeTakeFirst()
      const parsedCommon = JSON.parse(deck?.common ? arrayBufferToUtf8(deck.common) : '{}')
      if (deck && parsedCommon.dyn === 0 && parsedCommon.conf === conf.id) {
        affectedDecks.push(deck.id)
      }
    }
  }
  await db
    .updateTable('cards')
    .set({ factor: 2500, usn: 0 })
    .where('factor', '!=', 0)
    .where('factor', '<=', 2000)
    .where((eb) => eb.or([eb('did', 'in', affectedDecks), eb('did', 'in', affectedDecks)]))
    .execute()
}
