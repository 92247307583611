import React from 'react'
import { View, ViewProps } from 'react-native'
import { CardComponentProperty } from '../../../../classes'
import Button from '../../../../components/Button'
import { useStyles } from '../../../../hooks'
import { Component } from '../../../../types'
import * as _styles from './CardEditorEnumProperty.styles'

export type CardEditorEnumPropertyProps = ViewProps & {
  property: CardComponentProperty & { type: 'enum' }
  onChange: (value: string) => void
}

const CardEditorEnumProperty: Component<CardEditorEnumPropertyProps> = ({
  onChange,
  property,
  ...others
}) => {
  const styles = useStyles(_styles)

  return (
    <View {...others} style={[styles.container, others.style]}>
      {property.values.map(({ value, label }, i) => (
        <Button
          size="compact"
          color={property.value === value ? 'primary' : 'contrast'}
          key={value}
          style={[
            styles.button,
            i === 0 && styles.firstButton,
            i === property.values.length - 1 && styles.lastButton
          ]}
          styles={{ text: styles.buttonText }}
          onPress={() => onChange(value)}
        >
          {label}
        </Button>
      ))}
    </View>
  )
}

export default CardEditorEnumProperty
