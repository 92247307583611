import { useEffect, useState } from 'react'
import { Card } from '../../../packages/misu/card/models'
import { useIsMounted } from '../useIsMounted'
import { useMisuStorage } from './useMisuStorage'

export type UseCardsResponse = {
  cards: Card[] | null
  refetch: () => Promise<Card[]>
  fetching: boolean
  error: unknown
  mutate: (newCards: Card[]) => void
}

export const useDeckCards = (deckId: string | null | undefined): UseCardsResponse => {
  const [cards, setCards] = useState<Card[] | null>(null)
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState<unknown>(null)
  const { storage, collection } = useMisuStorage()
  const mounted = useIsMounted()

  useEffect(() => {
    if (!storage || !deckId) return
    setFetching(true)
    fetch()
  }, [storage, collection])

  const fetch = async (): Promise<Card[]> => {
    if (!storage || !deckId) throw new Error()
    try {
      const cards = await storage.card.getAllCardsInDeck(deckId)
      if (!mounted) return []
      setCards(cards)
      setFetching(false)
      return cards
    } catch (err) {
      if (mounted) {
        setError(err)
        setCards(null)
        setFetching(false)
      }
      throw err
    }
  }

  return {
    cards,
    fetching,
    error,
    refetch: fetch,
    mutate: (newCards) => setCards(newCards)
  }
}
