import { StyleProp, TextStyle } from 'react-native'
import { Theme } from '../../themes'
import { transparentize } from '../../util'

export const container = ({ background }: Theme): TextStyle => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  backgroundColor: background.contrast,
  padding: 8
})

export const button = ({}: Theme): TextStyle => ({
  height: 60,
  width: 90,
  paddingVertical: 10,
  paddingBottom: 22,
  borderRadius: 999
})

export const buttonActive = ({ colors }: Theme): TextStyle => ({
  backgroundColor: transparentize(colors.primary.main, 0.2)
})

export const buttonIcon = ({ fonts }: Theme): TextStyle & { fill: string; stroke: string } => ({
  fill: fonts.textMain.color,
  stroke: fonts.textMain.color
})

export const buttonIconActive = ({
  colors
}: Theme): StyleProp<TextStyle & { fill: string; stroke: string }> => ({
  fill: colors.primary.main,
  stroke: colors.primary.main
})

export const buttonText = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textMain,
  fontSize: 14,
  position: 'absolute',
  bottom: 2,
  left: 0,
  width: '100%',
  textAlign: 'center',
  fontWeight: '400',
  flexShrink: 0
})

export const buttonTextActive = ({ colors }: Theme): TextStyle => ({
  color: colors.primary.main,
  fontWeight: '500'
})
