import { TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const container = ({}: Theme): ViewStyle => ({
  padding: 16,
  gap: 12,
  flex: 1
})

export const heading = ({ fonts }: Theme): TextStyle => ({
  ...fonts.titleRegular,
  fontSize: 24
})

export const emptyText = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textMain,
  fontSize: 18
})

export const cardPreviewContainer = ({ background, radius }: Theme): ViewStyle => ({
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: background.card,
  paddingHorizontal: 16,
  paddingVertical: 8,
  borderRadius: radius.card
})

export const cardPreviewText = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textMain,
  flex: 1
})

export const cardPreviewDeleteButton = (): TextStyle => ({
  height: 32,
  width: 32,
  margin: -4
})

export const addCardModal = (): ViewStyle => ({
  width: '100%',
  maxWidth: 280,
  height: '90%',
  maxHeight: 400,
  gap: 12
})

export const fieldScrollView = ({}: Theme): ViewStyle => ({
  flex: 1
})

export const fieldHeading = ({ fonts }: Theme): TextStyle => ({
  ...fonts.label,
  marginBottom: -4
})

export const fieldContainer = ({}: Theme): ViewStyle => ({
  gap: 8
})

export const field = ({}: Theme): ViewStyle => ({
  gap: 4
})

export const fieldInput = (): ViewStyle => ({
  height: 40,
  paddingVertical: 0,
  borderRadius: 8
})

export const fieldText = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textMain,
  fontSize: 16
})
