import { Kysely } from 'kysely'
import { NoteType } from '../../note-type/models'
import { Database } from '../types'

export class NoteTypeStorage {
  private db: Kysely<Database>

  constructor(db: Kysely<Database>) {
    this.db = db
  }

  async get(id: string, executor: Kysely<Database> = this.db): Promise<NoteType> {
    const row = await executor
      .selectFrom('note_type')
      .selectAll()
      .where('note_type.id', '=', id)
      .executeTakeFirstOrThrow()
    if (!row) {
      throw new Error('Note type not found')
    }
    return new NoteType(row)
  }

  async getAll(executor: Kysely<Database> = this.db): Promise<NoteType[]> {
    const rows = await executor.selectFrom('note_type').selectAll().execute()

    return rows.map((row) => new NoteType(row))
  }

  async create(noteType: NoteType): Promise<void> {
    const results = await this.db
      .insertInto('note_type')
      .values(noteType.toDTO())
      .executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert note type.')
    }
  }

  async createMultiple(noteTypes: NoteType[]): Promise<void> {
    const results = await this.db
      .insertInto('note_type')
      .values(noteTypes.map((noteType) => noteType.toDTO()))
      .executeTakeFirst()
    if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
      throw new Error('Could not insert note types.')
    }
  }
}
