import pathModule from 'path'
import { FileSystemHandler, FolderReadResItem } from '../interfaces/filesystem/types'
import { ZipHandler } from '../interfaces/zip-handler/types'
import { nanoid } from '../misu/utils'

export const readAnkiApkgFile = async (
  apkgPath: string,
  handlers: {
    zipHandler: ZipHandler
    fsHandler: FileSystemHandler
  }
): Promise<{ files: FolderReadResItem[]; path: string }> => {
  const folderPath = pathModule.join(handlers.fsHandler.getBasePath(), `anki-import-${nanoid(6)}`)
  await handlers.zipHandler.unzipToFolder(apkgPath, folderPath)

  const files = await handlers.fsHandler.readDir(folderPath)
  return {
    files,
    path: folderPath
  }
}
