import { useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { CardRenderData } from '../../../packages/misu/collection/types'
import {
  MisuRenderer,
  MisuRendererReturnArgs
} from '../../../packages/misu/rendering/misu-renderer'
import { MEDIA_FOLDER } from '../../constants'
import { useTheme } from '../../state/theme'
import { Theme } from '../../themes'

export type UseCardRenderArgs = {
  cardRender: CardRenderData | null
}

const defaultStyles = ({ background, colors, fonts }: Theme) => `
  html {
    height: 100%;
  }
  body {
    font-size: ${Platform.OS === 'web' ? 1 : 2}rem;
    font-family: Arial;
    font-weight: bold;
    background-color: ${background.card} !important;
    padding: 0.5rem !important;
    padding-top: 0.375rem;
    color: ${fonts.textMain.color};
    justify-content: center;
    overflow-x: hidden !important;
    max-width: 100vw !important;
    overflow-y: auto;
    overflow-wrap: anywhere;
    margin: 0;
    padding: 0;
    height: 100%;
  }
  body::before {
    content: ' ';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${background.card} !important;
    z-index: -10;
  }
  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 80vh;
    border-radius: 0.5rem;
  }
  hr {
    all: unset;
    background-color: ${fonts.textSecondary.color} !important;
    margin-top: 1rem;
    height: 2px;
    width: 100% !important;
  }
  * {
    font-family: Arial !important;
    user-select: none !important;
    background-color: transparent !important;
    box-sizing: border-box;
  }
  audio {
    margin-block: 0.5rem;
  }
  .ruby-text-container {
    position: relative;
    margin-top: 0.4em;
    display: inline-block;
  }
  .ruby-text {
    display: flex;
    flex-direction: row !important;
    white-space: nowrap;
    left: 50%;
    position: absolute;
    top: 0.5em;
    transform: translateY(-100%) translateX(-50%);
    font-size: max(0.4em, 0.9rem);
    color: ${fonts.textSecondary.color};
  }
  video {
    width: 100%;
  }
  .audio-container {
    display: flex;
    padding-right: 0.75rem;
    gap: 1rem;
    align-items: center;
    background-color: ${background.cardLight};
    border-radius: 999rem;
    margin-block: 0.5rem;
    border: 1px solid #666;
  }
  .audio-button {
    padding: 0.375rem;
    appearance: none;
    -webkit-appearance: none;
    font-size: 1rem;
    border-radius: 999rem;
    background-color: ${colors.primary.main};
    border: none;
    outline: none;
    height: 2.75rem;
    width: 2.75rem;
    margin: -1px
  }
  .audio-button svg {
    color: ${colors.primary.contrastText};
  }
  .audio-time-bar {
    position: relative;
    flex: 1;
    background-color: #ccc;
    height: 0.75rem;
    border-radius: 999rem;
    margin-left: 0.5rem;
    appearance: none;
    -webkit-appearance: none;
    border: none;
    outline: none;
  }
  .audio-time-bar::before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    width: calc(var(--time-frac) * 100%);
    height: 1rem;
    background-color: ${colors.primary.main};
    border-radius: 999rem;
    transform: translateY(-50%);
    pointer-events: none;
  }
  .audio-time-bar::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: calc(var(--time-frac) * 100%);
    height: 1.5rem;
    width: 1.5rem;
    background-color: ${colors.primary.dark};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 999rem;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  .audio-label {
    font-size: 1rem;
  }
  button, a {
    pointer-events: auto;
  }
`

const defaultJs = ({}: Theme) => `
  window.ReactNativeWebView = window.ReactNativeWebView ?? {
    postMessage: (data) => {
      parent.postMessage(data)
    }
  }
  setTimeout(() => {
    const disableEvents = (e) => {
      window.ReactNativeWebView.postMessage("disable_events")
    }
    const dragStart = () => {
      window.ReactNativeWebView.postMessage("drag_start")
    }
    const dragEnd = () => {
      window.ReactNativeWebView.postMessage("drag_end")
    }
    const press = () => {
      window.ReactNativeWebView.postMessage("press")
    }
    const dragMove = () => {
      window.ReactNativeWebView.postMessage("drag_move")
    }
    const isScrollable = function (ele) {
      const hasScrollableContent = ele.scrollHeight > ele.clientHeight;
      const overflowYStyle = window.getComputedStyle(ele).overflowY;
      const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

      return hasScrollableContent && !isOverflowHidden;
    }

    const audioContainers = Array.from(document.querySelectorAll('.audio-container'))
    const playIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M112 111v290c0 17.44 17 28.52 31 20.16l247.9-148.37c12.12-7.25 12.12-26.33 0-33.58L143 90.84c-14-8.36-31 2.72-31 20.16z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/></svg>'
    const pauseIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M176 96h16v320h-16zM320 96h16v320h-16z"/></svg>'
    window.ReactNativeWebView.postMessage('------')
    audioContainers.forEach((container, i) => {
      window.ReactNativeWebView.postMessage('IN AUDIO CONTAINER: ' + i)
      const audio = container.querySelector("audio")
      audio.volume = '1.0'
      const playButton = document.createElement('button')
      playButton.innerHTML = playIcon
      playButton.classList.add('audio-button')

      window.ReactNativeWebView.postMessage(audioContainers.length + '-' + i)
      container.appendChild(playButton)

      const timeBar = document.createElement('button')
      timeBar.classList.add('audio-time-bar')
      container.appendChild(timeBar)

      const audioText = document.createElement('div')
      audioText.classList.add('audio-label')
      audioText.innerText = '00:00 / 00:00'
      container.appendChild(audioText)

      const zeroPad2 = (num) => {
        const strNum = num.toString()
        if (strNum.length === 1) return "0" + strNum
        return strNum
      }
      const timePadSecs = (secs) => {
        secs = Math.floor(secs)
        let mins = Math.floor(secs / 60)
        let remainingSecs = secs - mins * 60
        return zeroPad2(mins) + ":" + zeroPad2(remainingSecs)
      }

      const updateTimeLabel = () => {
        let frac = !audio.duration ? 0 : audio.currentTime / audio.duration
        container.style.setProperty("--time-frac", frac.toString())
        audioText.innerHTML = timePadSecs(audio.currentTime) + " / " + timePadSecs(audio.duration)
      }
      let dragging = false

      const updateTime = (e) => {
        if (!dragging) return
        let timeBounds = timeBar.getBoundingClientRect()
        let percent = Math.max(Math.min((e.x - timeBounds.left) / timeBounds.width, 1), 0)
        audio.currentTime = audio.duration * percent
        updateTimeLabel()
      }
      updateTimeLabel()
      let prevPlaying = !audio.paused
      timeBar.addEventListener('pointerdown', (e) => {
        window.ReactNativeWebView.postMessage("down")
        dragging = true
        audio.pause()
        updateTime(e)
      })

      window.addEventListener('pointerup', () => {
        window.ReactNativeWebView.postMessage("up")
        if (!dragging) return
        dragging = false
        if (prevPlaying) audio.play()
      })

      audio.addEventListener('durationchange', updateTimeLabel)

      audio.addEventListener('play', () => {
        playButton.innerHTML = pauseIcon
        prevPlaying = true
      })
      audio.addEventListener('pause', () => {
        playButton.innerHTML = playIcon
        prevPlaying = false
      })
      audio.addEventListener('ended', () => {
        playButton.innerHTML = playIcon
        prevPlaying = false
      })
      audio.addEventListener('timeupdate', updateTimeLabel)
      playButton.addEventListener('click', () => {
        if (audio.paused || audio.ended) {
          audio.play()
        } else audio.pause()
      })

      document.body.addEventListener('scroll', (e) => {
        disableEvents(e)
      })

    })

    window.ReactNativeWebView.postMessage("loaded")
    document.querySelectorAll('button, a, video').forEach((button) => {
      button.addEventListener('pointerdown', (e) => {
        e.stopPropagation()
        disableEvents()
      })
      button.addEventListener('pointerup', (e) => e.stopPropagation())
      button.addEventListener('pointermove', (e) => e.stopPropagation())
    })
    // document.body.addEventListener('pointerdown', dragStart)
    // document.body.addEventListener('pointermove', dragMove)
    // document.body.addEventListener('pointerup', dragEnd)
    document.body.addEventListener('click', press)
  })
`

export const useCardRenderHtml = (args: UseCardRenderArgs): MisuRendererReturnArgs => {
  const theme = useTheme()
  const renderer = new MisuRenderer()
  const [rendered, setRendered] = useState({
    frontHtml: '',
    backHtml: ''
  })

  useEffect(() => {
    if (!args.cardRender) return
    let unmounted = false

    renderer
      .generateFullHtml({
        cardRender: args.cardRender,
        defaultCss: defaultStyles(theme),
        defaultJs: defaultJs(theme),
        mediaFolder: MEDIA_FOLDER
      })
      .then((res) => {
        if (unmounted) return
        setRendered(res)
      })

    return () => {
      unmounted = true
    }
  }, [args.cardRender?.card.id, theme.label])

  return rendered
}
