import React from 'react'
import { View, ViewProps, Text } from 'react-native'
import { CardComponentNumberProperty } from '../../../../classes'
import { useStyles } from '../../../../hooks'
import { Component } from '../../../../types'
import Slider from '../../../Slider'
import * as _styles from './CardEditorNumberProperty.styles'

export type CardEditorNumberPropertyProps = ViewProps & {
  property: CardComponentNumberProperty
  onChange: (value: number) => void
}

const CardEditorNumberProperty: Component<CardEditorNumberPropertyProps> = ({
  onChange,
  property,
  ...others
}) => {
  const styles = useStyles(_styles)

  const min = property.min ?? 0
  const max = property.max ?? 100

  return (
    <View {...others} style={[styles.container, others.style]}>
      <Text style={styles.text}>{min}</Text>
      <Slider
        containerStyle={styles.slider}
        step={property.step ?? 1}
        minimumValue={min}
        maximumValue={max}
        value={property.value}
        animationType="timing"
        renderAboveThumbComponent={undefined}
        onValueChange={(nums) => {
          onChange(nums[0])
        }}
      />
      <Text style={styles.text}>{max}</Text>
    </View>
  )
}

export default CardEditorNumberProperty
