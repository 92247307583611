import React from 'react'
import { Text } from 'react-native'
import AudioIcon from '../../../svg/Sound'
import { CardComponent, CardComponentProperty, CardComponentStatic } from '../CardComponent'

export type AudioPropertyMap = {}

class _AudioCardComponent implements CardComponent {
  public static label = 'Audio'
  public static icon = AudioIcon
  public static group = 'Media'
  public static key = 'Audio'

  private onChangeFunc: (<Prop extends keyof AudioPropertyMap>(
    key: Prop,
    value: AudioPropertyMap[Prop]
  ) => void)[] = []

  getProperties(): CardComponentProperty[] {
    return []
  }

  setProperty<Prop extends keyof AudioPropertyMap>(
    key: Prop,
    value: AudioPropertyMap[Prop]
  ): CardComponent {
    this[key] = value as any
    this.onChangeFunc?.forEach((func) => func(key, value))
    return this
  }

  onPropChange(
    func: <Prop extends keyof AudioPropertyMap>(key: Prop, value: AudioPropertyMap[Prop]) => void
  ): void {
    this.onChangeFunc.push(func)
  }

  removeOnPropChange(
    func: <Prop extends keyof AudioPropertyMap>(key: Prop, value: AudioPropertyMap[Prop]) => void
  ) {
    this.onChangeFunc = this.onChangeFunc.filter((currFunc) => currFunc !== func)
  }

  getRendered(): (props: Record<string, unknown>) => JSX.Element {
    return (props) => (
      <React.Fragment {...props}>
        <Text>Audio</Text>
      </React.Fragment>
    )
  }

  toObject(): { key: string; props: Record<string, unknown> } {
    return {
      key: AudioCardComponent.key,
      props: {}
    }
  }

  static fromObject(obj: { key: string; props: Record<string, unknown> }) {
    if (obj.key !== AudioCardComponent.key) throw "Keys don't match"
    return new _AudioCardComponent()
  }
}

export const AudioCardComponent = _AudioCardComponent satisfies CardComponentStatic
export default AudioCardComponent
