import { Kysely } from 'kysely'
import { Collection } from '../../collection/models'
import { MisuStorage } from '../models'
import { Database } from '../types'

export class CollectionStorage {
  private db: Kysely<Database>
  private parent: MisuStorage

  constructor(db: Kysely<Database>, parent: MisuStorage) {
    this.db = db
    this.parent = parent
  }

  async setSchedulerDate(schedulerInitDate: number): Promise<void> {
    await this.db
      .updateTable('collection')
      .set({ scheduler_init_date: schedulerInitDate })
      .executeTakeFirstOrThrow()
  }

  async createIfDoesNotExist(collection: Collection): Promise<void> {
    await this.db.transaction().execute(async (trx) => {
      const existingCollection = await trx.selectFrom('collection').selectAll().executeTakeFirst()
      if (existingCollection) {
        throw new Error('Collection already exists.')
      }
      const results = await trx
        .insertInto('collection')
        .values(collection.toDTO())
        .executeTakeFirst()
      if (!results.numInsertedOrUpdatedRows || results.numInsertedOrUpdatedRows < 1) {
        throw new Error('Could not insert collection.')
      }
    })
  }

  async exists(): Promise<boolean> {
    const collection = await this.db.selectFrom('collection').selectAll().executeTakeFirst()
    return !!collection
  }

  async get(): Promise<Collection> {
    const collection = await this.db.selectFrom('collection').selectAll().executeTakeFirst()
    if (!collection) {
      throw new Error('Collection does not exist.')
    }
    return new Collection(collection, this.parent)
  }
}
