import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { Card } from '../card/models'
import { CardQueue } from '../card/types'
import { Collection } from '../collection/models'
import { Review } from '../review/models'
import { Platform } from '../review/types'
import { DEFAULT_FSRS_PARAMS } from '../scheduler/fsrs/constants'
import { FSRS } from '../scheduler/fsrs/fsrs'
import { Counts, Rating } from './types'

dayjs.extend(isSameOrAfter)

export class StudyQueue {
  learningQueue: Card[]
  queue: Card[]
  private history: [Card, Review][]
  private collection: Collection
  private currentCard: Card | null
  private enableFuzz: boolean = true
  private nextCard: Card | null
  private readonly totalCards: number
  counts: Counts

  constructor(queue: Card[], learningQueue: Card[], counts: Counts, collection: Collection) {
    this.totalCards = queue.length + learningQueue.length
    this.queue = queue
    this.counts = counts
    this.collection = collection
    this.learningQueue = learningQueue
    this.currentCard = this.getNextCardFromQueue()
    this.nextCard = this.getNextCardFromQueue()
    this.history = []
  }

  private nextLearningCard(): Card | null {
    return this.learningQueue.length > 0 ? this.learningQueue[0] : null
  }

  // If there is a learning card and it is due return it
  // Otherwise, if there is a card in the queue return it
  // Otherwise, if there is no card in the queue but there is a learning card return it
  // Otherwise, return null as there are no cards left to review
  private getNextCardFromQueue(): Card | null {
    let nextCard: Card | null = null
    const learningCard = this.nextLearningCard()
    const card = this.queue.length > 0 ? this.queue[0] : null
    if (learningCard && dayjs().isSameOrAfter(dayjs.unix(learningCard.due))) {
      nextCard = this.learningQueue.shift()!
    } else if (card) {
      nextCard = this.queue.shift()!
    } else if (!card && learningCard) {
      nextCard = this.learningQueue.shift()!
    } else {
      nextCard = null
    }
    return nextCard
  }

  private async reviewCard(rating: Rating, timeTaken: number) {
    if (!this.currentCard) throw new Error('No current card')
    const fsrs = new FSRS(
      DEFAULT_FSRS_PARAMS.weights,
      DEFAULT_FSRS_PARAMS.retention,
      this.enableFuzz
    )
    const { card, review } = fsrs.review(this.currentCard, rating, timeTaken)
    this.updateCounts(review)
    this.updateQueues(card)
    this.updateHistory(card, review)
    await this.saveReviewData(card, review)
    this.currentCard = this.nextCard
    this.nextCard = this.getNextCardFromQueue()
  }

  private async saveReviewData(card: Card, review: Review) {
    await this.collection.saveReviewData(card, review)
  }

  private updateQueues(card: Card) {
    if (card.type === CardQueue.Learning || card.type === CardQueue.Relearning) {
      this.learningQueue.push(card)
    }
  }

  private updateHistory(card: Card, review: Review) {
    this.history.push([card, review])
  }

  private updateCounts(review: Review) {
    switch (review.rating) {
      case Rating.Correct:
        switch (review.type) {
          case CardQueue.New:
            this.counts.new--
            this.counts.learning++
            break
          case CardQueue.Relearning:
          case CardQueue.Learning:
            this.counts.learning--
            break
          case CardQueue.Review:
            this.counts.review--
            break
        }
        break
      case Rating.Incorrect:
        switch (review.type) {
          case CardQueue.New:
            this.counts.new--
            this.counts.learning++
            break
          case CardQueue.Relearning:
          case CardQueue.Learning:
            break
          case CardQueue.Review:
            this.counts.review--
            this.counts.learning++
            break
        }
        break
    }
  }

  private undoCounts(oldCard: Card, review: Review) {
    switch (review.rating) {
      case Rating.Correct:
        switch (oldCard.type) {
          case CardQueue.New:
            this.counts.new++
            this.counts.learning--
            break
          case CardQueue.Relearning:
          case CardQueue.Learning:
            this.counts.learning++
            break
          case CardQueue.Review:
            this.counts.review++
            break
        }
        break
      case Rating.Incorrect:
        switch (oldCard.type) {
          case CardQueue.New:
            this.counts.new++
            this.counts.learning--
            break
          case CardQueue.Relearning:
          case CardQueue.Learning:
            break
          case CardQueue.Review:
            this.counts.review++
            this.counts.learning--
            break
        }
        break
    }
  }

  private undoCards(oldCard: Card) {
    if (this.currentCard && this.currentCard.id !== oldCard.id) {
      if (this.nextCard) {
        this.queue.unshift(this.nextCard)
        this.nextCard = this.currentCard
      } else {
        this.nextCard = this.currentCard
      }
      this.currentCard = oldCard
    } else {
      this.currentCard = oldCard
    }
  }

  progress() {
    const completed = this.totalCards - this.counts.new - this.counts.learning - this.counts.review
    return { completed, total: this.totalCards }
  }

  canUndo() {
    if (this.history.length === 0) {
      return false
    }
    const [_, review] = this.history[this.history.length - 1]
    return review.platform === Platform.Misu
  }

  hasCardsLeft() {
    return (
      this.queue.length > 0 ||
      this.learningQueue.length > 0 ||
      this.nextCard !== null ||
      this.currentCard !== null
    )
  }

  async undo() {
    if (!this.canUndo()) {
      throw new Error('No history to undo')
    }
    const [card, review] = this.history.pop()!
    const fsrs = new FSRS(
      DEFAULT_FSRS_PARAMS.weights,
      DEFAULT_FSRS_PARAMS.retention,
      this.enableFuzz
    )
    const oldCard = fsrs.undo(card, review)
    this.undoCounts(oldCard, review)
    await this.collection.saveUndo(oldCard, review)
    this.undoCards(oldCard)
  }

  async getCardRenderData(card: Card) {
    return await this.collection.getCardRenderData(card)
  }

  getCurrentCard() {
    return this.currentCard
  }

  getNextCard() {
    return this.nextCard
  }

  async correct(timeTaken: number) {
    await this.reviewCard(Rating.Correct, timeTaken)
  }

  async incorrect(timeTaken: number) {
    await this.reviewCard(Rating.Incorrect, timeTaken)
  }
}
