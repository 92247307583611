import { MisuRendererTransformer } from './misu-renderer'

const disallowedTagRegex = /<(\/)?(html|body)>/

export const tagRemoverTransformer: MisuRendererTransformer = {
  getTransformTarget(html) {
    const match = disallowedTagRegex.exec(html)
    if (!match) return null
    return {
      index: match.index,
      str: match[0]
    }
  },
  transform(transformTarget, html) {
    return html.replace(transformTarget.str, '')
  },
  setup: undefined
}
