import { create } from 'zustand'

export type DeckListScreenStore = {
  onChange: () => void
  setOnChange: (func: () => void) => void
}

export const useDeckListScreenStore = create<DeckListScreenStore>((set) => ({
  onChange: () => {},
  setOnChange: (func) => set({ onChange: func })
}))
