import { TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../../../themes'

export const container = ({}: Theme): ViewStyle => ({
  gap: 8,
  flexDirection: 'row',
  alignItems: 'stretch'
})

export const child = (): ViewStyle => ({
  flex: 1
})

export const select = (): ViewStyle => ({
  borderRadius: 8,
  paddingHorizontal: 12,
  paddingVertical: 8,
  height: 'auto'
})

export const input = ({ fonts, colors }: Theme): TextStyle => ({
  ...fonts.textMain,
  paddingHorizontal: 12,
  paddingVertical: 8,
  borderRadius: 8,
  borderWidth: 1,
  borderColor: colors.input.border,
  backgroundColor: colors.input.background,
  height: 42
})

export const emptyText = ({ fonts }: Theme): TextStyle => ({
  ...fonts.textMain,
  marginTop: 10,
  paddingLeft: 4
})
