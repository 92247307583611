import { TextStyle, ViewStyle } from 'react-native'
import { Theme } from '../../../themes'

export const container = ({ background }: Theme): ViewStyle => ({
  height: 300,
  backgroundColor: background.card
})

export const title = ({ fonts, background }: Theme): TextStyle => ({
  ...fonts.titleRegular,
  fontSize: 22,
  padding: 8,
  backgroundColor: background.cardLight
})

export const closeButton = (): TextStyle => ({
  position: 'absolute',
  top: -2,
  right: 0
})

export const inner = (): ViewStyle => ({
  padding: 8,
  marginVertical: 4,
  gap: 16
})

export const propertyContainer = ({ background, radius }: Theme): ViewStyle => ({
  paddingHorizontal: 12,
  paddingVertical: 8,
  gap: 8,
  backgroundColor: background.cardLight,
  borderRadius: radius.card
})

export const textContainer = (): ViewStyle => ({
  flexDirection: 'row'
})

export const propertyLabel = ({ fonts }: Theme): TextStyle => ({
  ...fonts.titleRegular,
  fontSize: 18
})

export const propertyValue = ({ fonts }: Theme): TextStyle => ({
  ...fonts.titleRegular,
  fontSize: 18,
  marginLeft: 8
})
