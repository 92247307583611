import React, { useState } from 'react'
import { StyleProp, TouchableOpacity, TouchableOpacityProps, View, ViewStyle } from 'react-native'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import { useStyles } from '../../hooks'
import { Component } from '../../types'
import * as _styles from './ResizableView.styles'

export type ResizableViewProps = TouchableOpacityProps & {
  axis?: 'x' | 'y' | 'xy'
  enabled?: boolean
  styles?: {
    innerContainer?: StyleProp<ViewStyle>
  }
  onDrag?: (diff: { x: number; y: number; pos: 'top' | 'left' | 'bottom' | 'right' }) => void
  onDragEnd?: (diff: { x: number; y: number; pos: 'top' | 'left' | 'bottom' | 'right' }) => void
}

const ResizableView: Component<ResizableViewProps> = ({
  axis = 'xy',
  enabled = true,
  styles: propStyles,
  onDrag,
  onDragEnd,
  ...others
}) => {
  const styles = useStyles(_styles)

  return (
    <TouchableOpacity activeOpacity={1} {...others} style={[styles.container, others.style]}>
      {enabled && (
        <>
          {axis.includes('x') && (
            <>
              <ResizableHandle
                pos="left"
                onDrag={(pos) => onDrag?.({ x: -pos.x, y: 0, pos: 'left' })}
                onDragEnd={(pos) => onDragEnd?.({ x: -pos.x, y: 0, pos: 'left' })}
              />
              <ResizableHandle
                pos="right"
                onDrag={(pos) => onDrag?.({ x: pos.x, y: 0, pos: 'right' })}
                onDragEnd={(pos) => onDragEnd?.({ x: pos.x, y: 0, pos: 'right' })}
              />
            </>
          )}
          {axis.includes('y') && (
            <>
              <ResizableHandle
                pos="top"
                onDrag={(pos) => onDrag?.({ x: 0, y: -pos.y, pos: 'top' })}
                onDragEnd={(pos) => onDragEnd?.({ x: 0, y: -pos.y, pos: 'top' })}
              />
              <ResizableHandle
                pos="bottom"
                onDrag={(pos) => onDrag?.({ x: 0, y: pos.y, pos: 'bottom' })}
                onDragEnd={(pos) => onDragEnd?.({ x: 0, y: pos.y, pos: 'bottom' })}
              />
            </>
          )}
        </>
      )}
      <View style={[styles.innerContainer, propStyles?.innerContainer]}>{others.children}</View>
    </TouchableOpacity>
  )
}

export default ResizableView

export type ResizableHandleProps = TouchableOpacityProps & {
  pos: 'top' | 'bottom' | 'left' | 'right'
  onDrag?: (args: { x: number; y: number }) => void
  onDragEnd?: (args: { x: number; y: number }) => void
}

export const ResizableHandle: Component<ResizableHandleProps> = ({
  pos,
  onDrag,
  onDragEnd,
  ...others
}) => {
  const styles = useStyles(_styles)
  const [_, setDragging] = useState(false)
  const [dragStart, setDragStart] = useState<{ x: number; y: number } | null>(null)
  const [dragCurrent, setDragCurrent] = useState<{ x: number; y: number } | null>(null)

  const gesture = Gesture.Pan()
    .runOnJS(true)
    .onStart((e) => {
      setDragging(true)
      setDragStart({ x: e.absoluteX, y: e.absoluteY })
      setDragCurrent({ x: e.absoluteX, y: e.absoluteY })
    })
    .onTouchesMove((e) => {
      if (!dragCurrent) return
      onDrag?.({
        x: e.allTouches[0].absoluteX - dragCurrent.x,
        y: e.allTouches[0].absoluteY - dragCurrent.y
      })
      setDragCurrent({ x: e.allTouches[0].absoluteX, y: e.allTouches[0].absoluteY })
    })
    .onTouchesUp((e) => {
      setDragging(false)
      setDragStart(null)
      setDragCurrent(null)
      onDragEnd?.({
        x: e.allTouches[0].absoluteX - (dragStart ? dragStart.x : 0),
        y: e.allTouches[0].absoluteY - (dragStart ? dragStart.y : 0)
      })
    })
    .onFinalize(() => {
      setDragging(false)
      setDragStart(null)
      setDragCurrent(null)
    })

  return (
    <GestureDetector gesture={gesture}>
      <TouchableOpacity
        style={[styles.handleContainer, styles[`handleContainer_${pos}`], others.style]}
      >
        <View style={styles.handleInner} />
      </TouchableOpacity>
    </GestureDetector>
  )
}
