import { Kysely } from 'kysely'
import { AnkiReview } from '../../../anki/review/types'
import { DatabaseSchema15 as AnkiDatabase } from '../../../anki/types'
import { ReviewFactory as MisuReviewFactory } from '../../review/factories'
import { Review } from '../../review/models'
import { MisuStorage } from '../../storage/models'

export class ReviewConverter {
  private misuDb: MisuStorage
  private ankiDb: Kysely<AnkiDatabase>

  constructor(misuDb: MisuStorage, ankiDb: Kysely<AnkiDatabase>) {
    this.misuDb = misuDb
    this.ankiDb = ankiDb
  }

  async insert(misuReviewsBatch: Review[]) {
    await this.misuDb.review.createMultiple(misuReviewsBatch)
  }

  private async processBatch(
    batch: AnkiReview[],
    cardIdMap: Record<number, string>
  ): Promise<void> {
    const misuReviewsBatch: Review[] = []
    for (const review of batch) {
      const misuReview = MisuReviewFactory.createFromAnkiDeck(review, cardIdMap)
      misuReviewsBatch.push(misuReview)
    }
    await this.insert(misuReviewsBatch)
  }

  async convert(cardIdMap: Record<number, string>): Promise<void> {
    const batchSize = 500
    const ankiReviews = await this.ankiDb.selectFrom('revlog').selectAll().execute()
    for (let i = 0; i < ankiReviews.length; i += batchSize) {
      const batch = ankiReviews.slice(i, i + batchSize)
      await this.processBatch(batch, cardIdMap)
    }
  }
}
