import React from 'react'
import { View, ViewProps } from 'react-native'
import CardEditor from '../../components/CardEditor'
import Screen from '../../components/Screen'
import { useStyles } from '../../hooks'
import { Component } from '../../types'
import * as _styles from './CardTypeCreate.styles'

export type DeckCreateProps = ViewProps & {}

const DeckCreate: Component<DeckCreateProps> = ({ ...others }) => {
  const styles = useStyles(_styles)

  return (
    <Screen {...others}>
      <View style={[styles.container, others.style]}>
        <CardEditor />
      </View>
    </Screen>
  )
}

export default DeckCreate
