import { useEffect, useState } from 'react'
import { getDbHandler } from '../../../packages/interfaces/sqlite/generic'
import { Collection } from '../../../packages/misu/collection/models'
import { MisuStorage, getStorageInstance } from '../../../packages/misu/storage/models'
import { DB_NAME } from '../../constants'
import { useUserStore } from '../../state/user'

export type UseMisuStorageResponse = {
  storage: MisuStorage | null
  fetching: boolean
  collection: Collection | null
}

export const useMisuStorage = (): UseMisuStorageResponse => {
  const { isNewUser } = useUserStore()
  const [storage, setStorage] = useState<MisuStorage | null>(null)
  const [collection, setCollection] = useState<Collection | null>(null)
  const [fetching, setFetching] = useState(false)

  const getStorage = async (unmounted: { current: boolean }) => {
    setFetching(true)
    const dbHandler = await getDbHandler()
    const newStorage = await getStorageInstance(DB_NAME, dbHandler)
    if (unmounted.current) return
    setStorage(newStorage)
    const collection = await newStorage.collection.get()
    if (unmounted.current) return
    setCollection(collection)
    setFetching(false)
  }

  useEffect(() => {
    if (isNewUser) return
    let unmounted = { current: false }
    getStorage(unmounted)
    return () => {
      unmounted.current = true
    }
  }, [isNewUser])

  return {
    storage,
    fetching,
    collection
  }
}
