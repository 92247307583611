import React from 'react'
import { View, ViewProps, Switch, Platform } from 'react-native'
import { CardComponentProperty } from '../../../../classes'
import { useStyles } from '../../../../hooks'
import { useTheme } from '../../../../state/theme'
import { Component } from '../../../../types'
import * as _styles from './CardEditorBooleanProperty.styles'

export type CardEditorBooleanPropertyProps = ViewProps & {
  property: CardComponentProperty & { type: 'boolean' }
  onChange: (newVal: boolean) => void
}

const CardEditorBooleanProperty: Component<CardEditorBooleanPropertyProps> = ({
  property,
  onChange,
  ...others
}) => {
  const styles = useStyles(_styles)
  const theme = useTheme()

  return (
    <View {...others} style={[styles.container, others.style]}>
      <Switch
        value={property.value}
        onValueChange={(newVal) => onChange(newVal)}
        trackColor={{
          false: Platform.OS !== 'ios' ? theme.colors.input.background : undefined,
          true: theme.colors.primary.main
        }}
      />
    </View>
  )
}

export default CardEditorBooleanProperty
