import { Kysely } from 'kysely'
import { AnkiDeck } from '../../../anki/deck/types'
import { DatabaseSchema15 as AnkiDatabase } from '../../../anki/types'
import { DeckFactory as MisuDeckFactory } from '../../deck/factories'
import { Deck } from '../../deck/models'
import { DeckConfigFactory as MisuDeckConfigFactory } from '../../deck-config/factories'
import { DeckConfig } from '../../deck-config/models'
import { MisuStorage } from '../../storage/models'

export class DeckConverter {
  private misuDb: MisuStorage
  private ankiDb: Kysely<AnkiDatabase>

  constructor(misuDb: MisuStorage, ankiDb: Kysely<AnkiDatabase>) {
    this.misuDb = misuDb
    this.ankiDb = ankiDb
  }

  async insert(misuDecksBatch: Deck[], misuDeckConfigsBatch: DeckConfig[]) {
    await this.misuDb.deck.createMultiple(misuDecksBatch, misuDeckConfigsBatch)
  }

  private async processBatch(batch: AnkiDeck[]): Promise<Record<number, string>> {
    const misuDecksBatch: Deck[] = []
    const misuDeckConfigsBatch: DeckConfig[] = []
    const deckIdMap: Record<number, string> = {}

    for (const deck of batch) {
      const deckConfig = MisuDeckConfigFactory.createDefault()
      const misuDeck = MisuDeckFactory.createFromAnkiDeck(deck, deckConfig.id)
      misuDecksBatch.push(misuDeck)
      misuDeckConfigsBatch.push(deckConfig)
      deckIdMap[deck.id] = misuDeck.id
    }
    await this.insert(misuDecksBatch, misuDeckConfigsBatch)
    return deckIdMap
  }

  async convert(): Promise<Record<number, string>> {
    const ankiDecks = await this.ankiDb.selectFrom('decks').selectAll().execute()
    let deckIdMap: Record<number, string> = {}
    const batchSize = 250
    for (let i = 0; i < ankiDecks.length; i += batchSize) {
      const batch = ankiDecks.slice(i, i + batchSize)
      deckIdMap = { ...deckIdMap, ...(await this.processBatch(batch)) }
    }
    return deckIdMap
  }
}
