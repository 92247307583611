import React from 'react'
import { ScrollView, View, ViewProps, Text, TextInput } from 'react-native'
import { useStyles } from '../../hooks'
import CloseIcon from '../../svg/Close'
import { Component } from '../../types'
import Button from '../Button'
import { CardEditorField, useCardEditorState } from '../CardEditor/CardEditor.store'
import { CardEditorPropertyView } from '../CardEditor/CardEditorPropertyList'
import IconButton from '../IconButton'
import * as _styles from './CardEditorSettings.styles'

export type CardEditorSettingsProps = ViewProps & {
  onCreate?: () => void
}

const CardEditorSettings: Component<CardEditorSettingsProps> = ({ onCreate, ...others }) => {
  const styles = useStyles(_styles)
  const state = useCardEditorState()

  return (
    <View {...others} style={[styles.container, others.style]}>
      <ScrollView>
        <View style={styles.innerContainer}>
          <Text style={styles.heading}>Settings</Text>
          <IconButton
            icon={CloseIcon}
            style={styles.closeButton}
            onPress={() => state.setSettingsOpen(false)}
          />
          <View style={styles.cardInner}>
            <CardEditorPropertyView
              fields={[]}
              onChange={(val) => state.setDirection(val as 'front' | 'back')}
              property={{
                key: 'direction',
                label: 'Direction',
                type: 'enum',
                values: [
                  { value: 'front', label: 'Front' },
                  { value: 'back', label: 'Back' }
                ],
                value: state.direction
              }}
            />
            <CardEditorPropertyView
              fields={[]}
              onChange={(val) => state.setPreview(val as boolean)}
              property={{
                key: 'preview',
                label: 'Preview',
                type: 'boolean',
                value: state.preview
              }}
            />
            <CardEditorFieldsProperty />
            <Button onPress={() => onCreate?.()}>Create Card Type</Button>
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

export default CardEditorSettings

export const CardEditorFieldsProperty = () => {
  const styles = useStyles(_styles)
  const { fields, setFields } = useCardEditorState()

  return (
    <CardEditorPropertyView
      fields={[]}
      property={{
        key: 'fields',
        label: 'Fields',
        type: 'custom'
      }}
      onChange={() => {}}
      style={styles.fieldProperty}
    >
      <>
        {fields.length === 0 && <Text style={styles.emptyFieldText}>No fields yet, add one</Text>}
        {fields.map((field, i) => (
          <FieldInput
            key={i}
            field={field}
            onDelete={() => {
              let newFields = [...fields]
              newFields.splice(i, 1)
              setFields(newFields)
            }}
            onFieldChange={(newField) => {
              let newFields = [...fields]
              newFields[i] = newField
              setFields(newFields)
            }}
          />
        ))}
        <Button
          size="compact"
          onPress={() => setFields([...fields, { name: 'New Field', value: '', description: '' }])}
        >
          Add Field
        </Button>
      </>
    </CardEditorPropertyView>
  )
}

export type FieldInputProps = {
  field: CardEditorField
  onFieldChange: (newField: CardEditorField) => void
  onDelete: () => void
}

const FieldInput: Component<FieldInputProps> = ({ field, onDelete, onFieldChange }) => {
  const styles = useStyles(_styles)

  return (
    <View style={{ flexDirection: 'row', gap: 6, alignItems: 'center' }}>
      <View style={{ gap: 6, flex: 1 }}>
        <View style={styles.fieldContainer}>
          <TextInput
            placeholder="Field name"
            style={styles.fieldInput}
            onChangeText={(name) => {
              onFieldChange({
                name,
                value: field.value,
                description: field.description
              })
            }}
            autoCapitalize="none"
            autoCorrect={false}
            defaultValue={field.name}
          />
          <TextInput
            placeholder="Placeholder Value"
            style={styles.fieldInput}
            onChangeText={(value) => {
              onFieldChange({
                name: field.name,
                value,
                description: field.description
              })
            }}
            autoCapitalize="none"
            autoCorrect={false}
            defaultValue={field.name}
          />
        </View>
        <TextInput
          placeholder="Description"
          style={styles.fieldInput}
          onChangeText={(description) => {
            onFieldChange({
              name: field.name,
              value: field.value,
              description
            })
          }}
          autoCapitalize="none"
          autoCorrect={false}
          defaultValue={field.description}
        />
      </View>
      <IconButton icon={CloseIcon} onPress={onDelete} />
    </View>
  )
}
