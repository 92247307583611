import { ViewStyle } from 'react-native'
import { Theme } from '../../themes'

export const container = ({}: Theme): ViewStyle => ({
  flex: 1
})

export const previewContainer = ({}: Theme): ViewStyle => ({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center'
})

export const draggingContainer = (): ViewStyle => ({
  position: 'absolute',
  zIndex: 100
})
