import { AnkiReview } from '../../anki/review/types'
import { BaseFactory } from '../models'
import { AnkiReviewAdapter } from './adapters'
import { Review } from './models'
import { CreateReviewType, Platform } from './types'

export class ReviewFactory extends BaseFactory {
  static create(data: CreateReviewType) {
    return new Review({ ...this.createBase(data), platform: Platform.Misu })
  }

  static createFromAnkiDeck(data: AnkiReview, cardIdMap: Record<number, string>): Review {
    const adapter = new AnkiReviewAdapter(data)
    const adaptedData = adapter.adapt(cardIdMap)
    const base = this.createBase(adaptedData)
    base.created_at = adaptedData.created_at
    base.updated_at = adaptedData.updated_at
    return new Review(base)
  }
}
